.ConsultantBtnAction {
  color: #fff;
  width: 100%;
  display: flex;
  text-align: center;
  border-radius: 15px;
  padding: 11px 13px;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}
