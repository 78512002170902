.custom-tabs.nav-tabs{
    border-bottom: none;
    justify-content: center;
    margin-top: 1rem;
    
}

.custom-tabs.nav-tabs .nav-item > button{
    border: 2px solid rgba(2,3,52,.102);
    margin: 0 10px;
    color: #020334;
    padding: 4px 20px 6px;
    border-radius: 15px;
    font-weight: 600;
    transition: all .5s ease 0s;
    background: 0 0;
    font-size: 20px;
    outline: none;
}
.custom-tabs.nav-tabs .nav-item > button.active{
    background: #FA5456;
    color: #fff;
    border-color: #FA5456;
}
.carouselItem{
    padding: 0 20px;
}
.join-consultants{
    background: #e9fcf8;
    padding: 4rem 2rem;
    border-radius: 20px;
}
.custom-tab-button{
    border: 2px solid rgba(2,3,52,.102);
    margin: 0 10px;
    color: #020334;
    padding: 4px 20px 6px;
    border-radius: 5px;
    font-weight: 600;
    transition: all .5s ease 0s;
    background: 0 0;
    font-size: 20px;
    outline: none;
}

.custom-tab-button.active{
    background: #FA5456;
    color: #fff;
    border-color: #FA5456;
}

  .divider {
    width: 50px;
    height: 3px;
    background-color: #f2994a;
    margin: 10px auto;
  }
  
  .step-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .step-container h5{
    font-size: 16px;
    font-weight: 600;
  }
  .step-container p{
    color: #807F80;
    font-size: 14px;
    line-height: normal;
    width: 150px;
    margin-top: 5px;
  }

  .circle {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    border: 1px solid;
  }
  
  .pink {
    color: #ED61A4;
    border-color: #ED61A4;
  }
  
  .blue {
    color: #1BCDDB;
    border-color: #1BCDDB;
  }

  .orange {
    color: #FF9640;
    border-color: #FF9640;
  }
  
  .arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .section-header{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .section-desc{
    font-size: 16px;
    font-weight: 400;
  }
