.react-calendar {
  width: 100%;
  border: 1px solid #EBEBEB;
  overflow: hidden;
  border-radius: 8px;
  padding: 1rem;
}
.react-calendar__tile {
  padding: 15px 6.6667px;
}
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #fff;
}
.react-calendar__tile--active abbr {
  background: var(--semiPurple);
  border-radius: 100px;
  padding: 10px 2rem;
}
