.HeaderTitle{
    color: #44474a;
    font-weight: 600;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 5px;
}
.HeaderPargraph{
    color: #737373;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
}
.FormContainer{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    background: var(--babyBlue);
}

.LoginBackground{
    width:100%
}

.FormPadding{
    padding: 1.5rem 10rem;
}

.VerifyInput{
    width: 60px;
    height: 70px;
    color: #848484;
    border-radius: 10px;
    background-color: transparent;
    text-align: center;
    font-size: 33px;
    font-weight: 400;
    border: 1px solid var(--Color-grey-50, #838080);

}

.textCenter{
    cursor: pointer;
}

@media (max-width: 768px) {
    .FormPadding{padding: 2rem;}
}
