.footer{   
    color: #fff;
    padding: 2rem 2rem 0rem 2rem;
    background: #01A5B1;
}
@media only screen and (max-width: 699px) {
    .footer{
        padding: 4rem 2rem;
    }
}
.FooterLogo img{
    /*filter: brightness(0) invert(1);*/
    width: 58px;
    margin-bottom: 1rem;
}

.FooterTitle{
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid #fff;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.Sitemap > p{
    margin-bottom: 10px;
}

.Sitemap  > a{
    color: #fff;
}
.SiteLink a{
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}

.ContactImg{
    width: 35px;
    height: auto;
    margin-right: 10px;
    border-radius: 50%;
}
.CopyRight{
    
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.SocialIcons{
    font-size: 50px;
}
@media (max-width: 768px) {
    .SocialIcons {
      text-align: left;
    }
    .SocialIcons svg{
        margin-left: 0;
    }
  }
.SocialIcon {
    
    padding: 5px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}
.aboutNurabi{
    font-size: 13px;
}