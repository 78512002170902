.consultant-pages .nav-link{
    background-color: transparent;
    padding: 20px 30px 20px;
    font-weight: 600;
    color: #1C0A26;
    font-size: 13px;
}
.consultant-pages .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--blue) ;
    background-color: transparent;
    border-bottom: 1px solid var(--blue);
    border-radius: 0;
}

.top-head-sidebar{
    background: #fff;
    padding: 20px;
}
.your-balance{
    background: #eee;
    padding: 0;
    font-weight: 500;
    font-size: 19px;
    margin:0 0 15px 0;
    border-radius: 10px;
    overflow: hidden;
}
.your-balance .title{
    padding: 10px 30px
}
.your-balance .value{
    background: #7b7b7b;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}

.consultant-pages li button{
    background-color: transparent !important;
}