.CardButton{
    text-align: center;
    width: 100%;
    outline: none;
    border: 1px solid var(--blue) !important;
    background: #fff !important;
    color: var(--blue) !important;
    border-radius: 8px;
    padding: 15px 50px;
    cursor: pointer;
    font-weight: 700;
}
.CardButton:hover,
.CardButton:active{
    background: #fff !important;
}