.card-button{
    text-align: center;
    width: 100%;
    background-color: var(--blue);
    outline: none;
    border: 2px solid var(--blue);
    border-radius: 8px !important;
    padding: 15px 40px;
    font-weight: 600;
}
.card-button:hover,
.card-button:active{
    background-color: var(--blue) !important; 
    border: 2px solid var(--blue);
}