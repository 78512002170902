.HeaderTitle{
    margin: 2rem 0;
    text-align: center;
    border-bottom: 1px solid #e3e3e3;
    line-height: .1em;
}

.HeaderTitle span{
    background: var(--babyBlue);
    padding: 0 10px;
  }
.HeaderPargraph{
    color: #b3b3b3;
    font-weight: 600;
}
.FormContainer{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    background: var(--babyBlue);
}

.FormPadding{
    padding: 5rem 10rem;
}

.Input{
    height: 48px;
    padding: 14px 25px;
    margin: 0px 5px;
    border-radius: 15px;
    background-color: #fff;
    border: none;
    color: #848484;
}

.SignUpBackground{
    width:100%
}

.error{
    color: red;
    margin-top: 10px;
}
@media (max-width: 699px) {

    .FormPadding{
        padding: 1rem;
    }
}


.EmailInput {
  height: 48px;
  padding: 14px 45px;
  margin: 0px 5px;
  border: 1px solid #192252 !important;
  border-radius: 8px !important;
  border: none;
  color: #848484;
  background: transparent;
}

.SignTitle{
    font-size: 30px;
    color: #101826;
    font-weight: 700;
  }
  .GrayTitle{
    font-size: 16px;
    font-weight: 400;
    color: #868686;
  }

  .GoogleSignIn,
.LoginOption {
    padding: 14px 25px;
    border-radius: 8px;
    color: #3E3E3E;
    width: 100%;
    display: flex;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    margin-top: 5px;
    justify-content: center;
    font-size: 17px;
    border: 1px solid #192252 !important;
    margin-top: 20px;
}

.GoogleSignIn img,
.LoginOption img {
  margin: 0 10px;
}