.comment-item{
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 5px 15px;
}
.comment-image{
    padding: 0 !important;
}
.comment-image img {
    
    max-width: 52px;
    width: 52px;
    height: 52px;
    
  }
.comment-details{
    padding: 0 !important;
}

.comment-details h4{
    font-weight: 600;
    font-size: 18px;
    color: #2A2A2A;
    margin: 5px 0;
    letter-spacing: 0.36px;
}

.comment-details > p{
    color: #2A2A2A;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.28px;
}
.comment-details .comment-provider{
    color: #08cad7;
    font-weight: bold;
}






.comment-price{
    font-size: 1.3rem;
    font-weight: bold;
    text-align: right;
}

.comment-price .discount-price{
    color:#FA5456
}

.comment-price .real-price{
    text-decoration: line-through;
    color: #b3b3b3;
    text-decoration-thickness: 2px;
}