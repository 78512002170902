.ConsultantTabsContainer {
  width: 100%;
}
.consultant-views-container {
  position: absolute;
  right: 0;
  top: 2.5rem;
}

.consultant-tabs.nav-tabs {
  border: none;
  margin-bottom: 2rem;
  border-radius: 8px;
  border: 1px solid #C1C1C1;
  background: var(--White, #FFF);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.consultant-tabs.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #fff;
}
.consultant-tabs.nav-tabs .nav-link {
  font-weight: 600;
  padding-right: 1rem;
  padding-left: 1rem;
  color: #7F7F7F;
  font-size: 15px;
}
.tab-content .about-title h4 {
  font-weight: bold;
  color: #6e6e6e;
}
.consultant-tabs.nav-tabs .nav-link.active {
  color: #fff;
  font-weight: 600;
  background: var(--semiPurple);
  border-radius: 8px;
  font-size: 15px;
  padding: 10px 25px;
}

@media (max-width: 768px) {
  .consultant-views-container {
    display: none;
  }
}

.header {
  height: 220px;
  background-color: #f8f8f8;
  margin-bottom: 3rem;
}

.breadcrumb {
  flex-direction: column;
}

.breadcrumb-header {
  color: #07b2bd;
  font-weight: 600;
}

.breadcrumb-details span {
  color: #a1a1a1;
  font-weight: 600;
}

.breadcrumb-details span.active-page {
  color: #08cad7;
}


@media only screen and (max-width: 699px) {
  .consultant-tabs.nav-tabs .nav-link {
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}