.header{
    height: 350px;
    background-color: var(--babyBlue);
    margin-bottom: 3rem;
}

.breadcrumb{
    flex-direction: column;
}

.breadcrumb-header{
    color:#1C0A26;
    font-weight: 600;
}

.breadcrumb-details span{
    color: #a1a1a1;
    font-weight: 600;
}

.breadcrumb-details span.active-page {
    color:#08cad7;
}
