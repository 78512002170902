@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Stylish&family=Tajawal:wght@500&display=swap);
/*@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');*/


* {
  box-sizing: border-box;
  --maincolor: #FA5456;
  --font: #434343;
  --font2: #737373;
  --inputback: #fafafa;
  --infofont: #3b71b0;
  --orange: #ffb448;
  --selected: #fed21a;
  --brown: #d38b23;
  --yellow: #f8eede;
  --gray: #afafaf;
  --red: #a5243d;
  --lightred: #ffeeee;
  --blue: #07b2bd;
  --gold: #fed21a;
  --lightorange: #e2c0c0;
  --darkGreen: #39b970;
  --lightGray: #8b8d9f;
  --whiteGray: #b3b5c5;
  --green: #07b2bd;
  --vLightGray: #bcbcbc;
  --darkOrange: #fe5c36;
  --regularGray: #d9d9d9;
  --white: #ffff;
  --black: black;
  --font2: #6a747d;
  --darkRed: #e54335;
  --redColor:#FF5B5B;
  --purple:#872F59;
  --semiPurple:#ED61A4;
  --babyBlue:#EFFBFF;
  --blue:#1BCDDB;
}

html,body {
  font-family: "Montserrat" !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.LoaderDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
}

.btn-blue,
.btn-blue:hover{
  background: var(--babyBlue) !important;
}

.header{
    height: 220px;
    background-color: #f8f8f8;
    margin-bottom: 3rem;
}

.breadcrumb{
    flex-direction: column;
}

.breadcrumb-header{
    color:#07b2bd;
    font-weight: 600;
}

.breadcrumb-details span{
    color: #a1a1a1;
    font-weight: 600;
}

.breadcrumb-details span.active-page {
    color:#08cad7;
}
.filters-title,
.filter-type-title{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;;
}
.filter-content{
    border-bottom: 2px solid #eee;
    padding: 20px 0;
}

.filter-content:last-child{
    border-bottom: none
}

.form-check label{
    color: #a1a1a1 !important;
    font-weight: 500;
}
.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked{
    background-color: #FA5456 !important;
    border-color: #FA5456 !important;
}

.form-check-input:active {
    filter: brightness(100%) !important;
  }
.dateTitle{
    color:#a1a1a1
}
  .calendarview{
    position: relative;
  }
  .calendar{
    position: absolute;
  }

  .date{
    padding-right: 20px;
    padding-left: 20px;
  }

 
  @media (max-width: 699px) {
  .filter-container{
    position: absolute;
    z-index: 4;
    background: #fff;
    width: 70%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1rem;
    left: -50rem;
  }
  .hidden-filter{
    left: 0rem !important; 
    display: block !important;
  }
}
.custom-input {
    border-radius: 8px;
    border: 1px #0c8af8 solid;
    padding: 4px 12px;
    background-color: white;
    height: 22px;
    box-shadow: 0 0 2px #0074d9;
    margin-top: 5px;
  }
  
  .custom-input:focus {
    outline: none;
    border: 1px solid #0c8af8;
    box-shadow: 0 0 10px 2px #0074d9;
  }
.courses-top-head{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;
}
.sort-container{
    margin-left: auto !important;
}
.pagination {
  text-align: center;
}
.pagination .page-item a {
  color: #07b2bd;
}
.pagination .page-link {
  min-width: 40px;
  min-height: 40px;
  border-radius: 50% !important;
  margin: 0 10px;
}
.pagination .active > .page-link {
  background-color: var(--redColor);
  border-color: var(--redColor);
}
.disabled>.page-link, .page-link.disabled{
  background-color: transparent;
}
.consultant-card{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
    cursor: pointer;
    min-height: auto;
}
.consultant-card-title {
    font-weight: bold;
    font-size: 18px;
    color: #7b7b7b;
}
.ConsAvatar{
   height: 250px;
}
.consultant-provider{
    color: #08cad7;
    font-weight: bold;
}
.consultant-rate .rate-stars{
    margin: 0;
}
.availabilty-number{
    padding: 5px 15px;
    border: 1px solid #9bf3f8;
    font-size: 1.2rem;
    border-radius: 15px;
    display: inline-block;
    background: #9bf3f8;
    color: #07B2BD;
}
.cons-lang:nth-child(odd){
    background: #07b2bd; 
    margin: 0 5px 0 0;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
}
.cons-lang:nth-child(even){
    background: #FA5456; 
    margin: 0 5px 0 0;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
}

body[lang="ar"] .availabilty-number{
    text-align: right;
    direction: rtl;
}
.ConsultantCardHorizontal_Item__PbQKX{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
    position: relative;
    padding: 0.5rem 1rem;
}
.ConsultantCardHorizontal_Image__3vdxn img{
    border-radius: 10px;
    width: 150px;
    height: 150px;
    max-width: 220px;
}
.ConsultantCardHorizontal_Languages__1Qa8Y span,
.ConsultantCardHorizontal_Languages__1Qa8Y p
{
    margin: 0 5px 0 0;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
}
.ConsultantCardHorizontal_Languages__1Qa8Y span:nth-child(odd),
.ConsultantCardHorizontal_Languages__1Qa8Y p{
    background: #FFB448;
}

.ConsultantCardHorizontal_Languages__1Qa8Y span:nth-child(even){
    background: #FA5456;
}
.ConsultantCardHorizontal_Languages__1Qa8Y p{
    padding: 4px 8px;
}
.ConsultantCardHorizontal_Availabilty__16qTe{
    padding: 4px 20px;
    border: 1px solid #9bf3f8;
    font-size: 1rem;
    border-radius: 5px;
    display: inline-block;
    background: #9bf3f8;
    color: #FA5456;
}

@media (max-width: 699px) {
    .ConsultantCardHorizontal_Image__3vdxn img{
        width: 100px;
        height: 100px;
    }    
    .ConsultantCardHorizontal_Item__PbQKX h5,
    .ConsultantCardHorizontal_Languages__1Qa8Y span,
    .ConsultantCardHorizontal_Languages__1Qa8Y p,
    .ConsultantCardHorizontal_Availabilty__16qTe{
        font-size: 14px !important;
    }
    

}
.header{
    height: 220px;
    background-color: #f8f8f8;
    margin-bottom: 3rem;
}

.breadcrumb{
    flex-direction: column;
}

.breadcrumb-header{
    color:#07b2bd;
    font-weight: 600;
}

.breadcrumb-details span{
    color: #a1a1a1;
    font-weight: 600;
}

.breadcrumb-details span.active-page {
    color:#08cad7;
}



.RateStars_RateStars__1ZHXc > div{
    justify-content: center;
}
.course-views{
    color: #b3b3b3;
    font-weight: 500;
    font-size: 1.2rem;
}
.course-views-icon{
    margin-right: 5px;
}
.course-title,
.course-header h5{
    font-weight: bold;
    color: #6e6e6e;
    
}
.course-header h5{
    margin-bottom: 2px;
}
.course-header p{
    color: #b3b3b3;
    font-weight: 500;
}
.course-description{
    color: #b3b3b3;
    font-weight: 500;
}
.course-provider{
    color: #08cad7;
    font-weight: bold;
    margin-bottom: 15px;
}


.section-container .collapse{
    padding: 10px 20px 10px 0;
}

.section-item-collapse{
    padding: 10px 20px;
    background-color: #f8f8f8;
    border-radius: 5px;
    /* cursor: pointer; */
    margin-top: 15px;
}

.section-title{
    margin-top: 0;
    margin-bottom: 0;
}

.section-item {
    align-items: flex-start;
    margin: 20px 0;
}

.section-item p{
    margin-bottom: 0;
}
.icon-type{
    padding: 10px 15px 15px;
    margin-right: 10px;
    border-radius: 7px;
    background-color: #fee;
    color: #FA5456;
}
.lock-icon{
    border-radius: 7px;
    color: #FA5456;
    font-size: 1.2rem;
}

.lecture-type{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}

.lecture-title a{
    color: #000;
}
.lecture-type a{
    color: #828282;
}
.rate-number{
    color: #7b7b7b;
    font-weight: 600;
    font-size: 1.15rem;
}

.actionButtonContainer{
    
    
    border-radius: 7px;
    background-color: #fff;
    color: #FA5456;
}

.actionButton{
    color: #FA5456;
    font-size: 1.5rem;
}
.shareDropdown .dropdown-toggle::after{
  display: none;
}
.shareDropdown .dropdown-menu.show{
  box-shadow: 0 8px 24px 0 rgba(183, 183, 183, 0.17)
}
.shareDropdown .btn.show{
  border: none;
  
}
.shareDropdown .dropdown-item{
  display: flex;
  align-items: center;

}
.shareDropdown .dropdown-item .social-name{
  margin: 0 10px;
}
.section-title{
    font-weight: 600;
}
.progress-bar{
    padding: 10px 0px;
}
.progress {
    --bs-progress-bar-bg: #FA5456;
}
.courseRate{
    font-weight: 600;
    margin-bottom: 0;
    color: #7b7b7b;

}
.starts-percentage{
    font-weight: bold;
    color: #7b7b7b;
}

.comment-item{
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 5px 15px;
}
.comment-image{
    padding: 0 !important;
}
.comment-image img {
    
    max-width: 52px;
    width: 52px;
    height: 52px;
    
  }
.comment-details{
    padding: 0 !important;
}

.comment-details h4{
    font-weight: 600;
    font-size: 18px;
    color: #2A2A2A;
    margin: 5px 0;
    letter-spacing: 0.36px;
}

.comment-details > p{
    color: #2A2A2A;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.28px;
}
.comment-details .comment-provider{
    color: #08cad7;
    font-weight: bold;
}






.comment-price{
    font-size: 1.3rem;
    font-weight: bold;
    text-align: right;
}

.comment-price .discount-price{
    color:#FA5456
}

.comment-price .real-price{
    text-decoration: line-through;
    color: #b3b3b3;
    text-decoration-thickness: 2px;
}
.card-button{
    text-align: center;
    width: 100%;
    background-color: #FF5659;
    outline: none;
    border: 2px solid #FF5659;
    border-radius: 8px !important;
    padding: 15px 40px;
    font-weight: 600;
}
.card-button:hover,
.card-button:active{
    background-color: #FF5659 !important; 
    border: 2px solid #FF5659;
}
.course-details-card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
}

.card-img-container{
    overflow: hidden;
    height: 240px;
    position: relative;
    border-radius: 10px;
}
.card-img-container img{
    width: 100%;
    height: auto;
}
.course-body{
    padding: 5px 35px;
}
.course-body h5{
    font-weight: bold;
    margin-bottom: 0;
    font-size: 1.1rem;
    color: #7b7b7b;
}
.course-features{
    margin-top: 5px;
}
.course-features p {
    margin: 0 0 5px 0;
}
.course-features p span {
    margin-right: 5px;
}
.discount-price{
    font-size: 1.3rem;
    color: #FA5456;
    font-weight: bold;
}

.real-price {
    text-decoration: line-through;
    text-decoration-thickness: auto;
    color: #b3b3b3;
    text-decoration-thickness: 2px;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
}
.discount-percentage{
    color: #08cad7;
    font-weight: bold;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.2);
    border-radius: 15px 15px 0 0 !important;
}
.preview-icon{
    color: white;
    font-size: 2rem;
}

.icon-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
}



.fixed {
    position: fixed;
    top: 10rem;
    width: 27%;
  }
  
  .regular {
    position: relative;
    top: auto;
    right: auto;
  }


  @media (max-width: 768px) {
    .course-details-card{
        margin-top: 0;
    }
    #fixed-card{
        position: initial;
        width: auto;
    }
}
video {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
}
.card-purple-button{
    text-align: center;
    width: 100%;
    background-color: var(--semiPurple);
    outline: none;
    border: 2px solid var(--semiPurple);
    border-radius: 8px !important;
    padding: 15px 40px;
    font-weight: 600;
}
.card-purple-button:hover,
.card-purple-button:active{
    background-color: var(--semiPurple) !important; 
    border: 2px solid var(--semiPurple);
}
.header{
    height: 220px;
    background-color: #f8f8f8;
    margin-bottom: 3rem;
}

.breadcrumb{
    flex-direction: column;
}

.breadcrumb-header{
    color:#07b2bd;
    font-weight: 600;
}

.breadcrumb-details span{
    color: #a1a1a1;
    font-weight: 600;
}

.breadcrumb-details span.active-page {
    color:#08cad7;
}

.content-details{
    position: relative;
}
.courses-top-head{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;
}
.sort-container{
    margin-left: auto !important;
}

.search-container{
    position: relative;
}
.search-icon{
    position: absolute;
    right: 4%;
    top: 30%;
}
/* .pagination-container{
   position: absolute;
   bottom: 0;
   left: 40%;
} */
@keyframes spinner_spinner__2KQAF {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .spinner_Container__8H2dA{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 5rem;
    min-height: 10rem;
  }
  .spinner_LoadingSpinner__1OQsP {
    animation: spinner_spinner__2KQAF 1.5s linear infinite;
    /* position: absolute; */
    z-index: 1000;
    width: 90px;
    height: auto;
    margin-top: 100px;
    /* margin-inline: 25%; */
    /* margin-block: 25%; */
  }
.course-card{
    /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 10px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
    cursor: pointer;
}
.course-card-title {
    font-weight: bold;
    font-size: 18px;
    color: #7b7b7b;
}
.course-provider{
    color: #08cad7;
    font-weight: bold;
  }
.filters-title,
.filter-type-title{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;;
}
.filter-content{
    border-bottom: 2px solid #eee;
    padding: 20px 0;
}

.filter-content:last-child{
    border-bottom: none
}

.form-check label{
    color: #a1a1a1 !important;
    font-weight: 500;
}
.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked{
    background-color: #FA5456 !important;
    border-color: #FA5456 !important;
}

.form-check-input:active {
    filter: brightness(100%) !important;
  }

.SearchInput_ConsultantsListSearch__2WPNh{
    background-color: #F9F9F9;
    border: 0;
    padding: 15px 20px 20px 25px;
    border-radius: 5px;
}

@media (max-width: 699px) {
    .SearchInput_ConsultantsListSearch__2WPNh{
        padding: 13px 20px 13px 50px;
    }
  }


.Lesson_lessonContainer__3C-FZ{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
}
.Lesson_lessonTopHeader__3zjRp{
    padding: 20px;
}
.Lesson_lessonTitle__sVccl h4{
    font-weight: bold;
    color: #6e6e6e;
}
.Lesson_leaveFeedback__HGvqd{
    margin-right: 10px;
}
.Lesson_leaveFeedback__HGvqd button{
    padding: 0px 20px;
}

@media (max-width: 768px) {
    .Lesson_lessonTopHeader__3zjRp{flex-direction: column;}
    .Lesson_cardProgressContainer__1xwuQ{padding: 1rem 3rem;}
    .Lesson_lessonContainer__3C-FZ{
        padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    }
}

.lesson-views-container{
    position: absolute;
    right: 0;
    top: 2.5rem;
}
.lesson-tabs.nav-tabs{
    border: none;
}
.lesson-tabs.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    border-color: #fff
}
.lesson-tabs.nav-tabs .nav-link{
    font-weight: 600;
    padding-right: 3rem;
    padding-left: 3rem;
    color: #7b7b7b;
    font-size: 1.2rem;
}
.lesson-tabs.nav-tabs .nav-link.active{
    color: #FA5456;
    border:none;
    border-bottom: 2px solid #FA5456;
    font-weight: 600;

}
.aboutCourse{
    color: #b3b3b3;
    font-weight: 500;
}
.lessonTabsContainer{
    padding: 30px 0px 10px 40px;
    position: relative;
}

@media (max-width: 768px) {
    .lesson-views-container{display: none;}
    .lessonTabsContainer{ padding: 0px; }
}
.CardProgress_CardProgress__1zbr0 > div > div{
    height: 15px;
}

.RateStars_RateStars__1flJt > div{
    justify-content: center;
}

.lessonTitle h4,
.languages h4{
    font-weight: bold;
    color: #6e6e6e;
}
.leave-feedback{
    margin-right: 10px;
}
.leave-feedback button{
    padding: 0px 20px;
}
.ConsultantTabsContainer {
  width: 100%;
}
.consultant-views-container {
  position: absolute;
  right: 0;
  top: 2.5rem;
}

.consultant-tabs.nav-tabs {
  border: none;
  margin-bottom: 2rem;
  border-radius: 8px;
  border: 1px solid #C1C1C1;
  background: var(--White, #FFF);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.consultant-tabs.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #fff;
}
.consultant-tabs.nav-tabs .nav-link {
  font-weight: 600;
  padding-right: 1rem;
  padding-left: 1rem;
  color: #7F7F7F;
  font-size: 15px;
}
.tab-content .about-title h4 {
  font-weight: bold;
  color: #6e6e6e;
}
.consultant-tabs.nav-tabs .nav-link.active {
  color: #fff;
  font-weight: 600;
  background: var(--semiPurple);
  border-radius: 8px;
  font-size: 15px;
  padding: 10px 25px;
}

@media (max-width: 768px) {
  .consultant-views-container {
    display: none;
  }
}

.header {
  height: 220px;
  background-color: #f8f8f8;
  margin-bottom: 3rem;
}

.breadcrumb {
  flex-direction: column;
}

.breadcrumb-header {
  color: #07b2bd;
  font-weight: 600;
}

.breadcrumb-details span {
  color: #a1a1a1;
  font-weight: 600;
}

.breadcrumb-details span.active-page {
  color: #08cad7;
}


@media only screen and (max-width: 699px) {
  .consultant-tabs.nav-tabs .nav-link {
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.section-title{
    font-weight: 600;
}
.progress-bar{
    padding: 10px 0px;
}
.progress {
    --bs-progress-bar-bg: #FA5456;
}
.courseRate{
    font-weight: 600;
    margin-bottom: 0;
    color: #7b7b7b;

}
.starts-percentage{
    font-weight: bold;
    color: #7b7b7b;
}

.react-calendar {
  width: 100%;
  border: 1px solid #EBEBEB;
  overflow: hidden;
  border-radius: 8px;
  padding: 1rem;
}
.react-calendar__tile {
  padding: 15px 6.6667px;
}
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #fff;
}
.react-calendar__tile--active abbr {
  background: var(--semiPurple);
  border-radius: 100px;
  padding: 10px 2rem;
}

.cancel-button{
    text-align: center;
    width: 100%;
    background-color: #fff;
    outline: none;
    border: none;
    border-radius: 8px;
    padding: 15px 40px;
    font-weight: 600;
    color: #FF5659;
    border: 2px solid #FF5659;
    
}
.cancel-button:hover,
.cancel-button:active{
    background-color: #FF5659 !important; 
    color: #fff;
    border: 1px solid #FF5659;
}
.AvailabilityInstructions_InstructionItem__2BdlB{
    background-color: #FA5456;
    border: 2px solid #FA5456;
    border-radius: 15px;
    padding: 10px 40px;
    color: #fff;
    text-align: center;
}
.AvailabilityInstructions_ItemNumber__1wUTI{
    border-radius: 50%;
    width: 30px;
    height: 25px;
    display: inline-block;
    border: 2px solid #FA5456;
    text-align: center;
    line-height: 20px;
    margin-right: 10px;
    padding: 0 5px;
}
.SecondryButton_CardButton__2GguU{
    text-align: center;
    width: 100%;
    outline: none;
    border: 1px solid var(--semiPurple) !important;
    background: #fff !important;
    color: var(--semiPurple) !important;
    border-radius: 8px;
    padding: 15px 50px;
    cursor: pointer;
    font-weight: 700;
}
.SecondryButton_CardButton__2GguU:hover,
.SecondryButton_CardButton__2GguU:active{
    background: #fff !important;
}
.ConsultantSessionsBtnActions_ConsultantBtnAction__-kv-s {
  color: #fff;
  width: 100%;
  display: flex;
  text-align: center;
  border-radius: 15px;
  padding: 11px 13px;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.course-card-link{
    padding: 0;
    color: #FA5456;
}
.page-title{
    font-weight: bold;
    color: #6e6e6e;
    padding: 5rem 0 2rem;
}
.custom-web-tabs.nav-tabs{
    border: none;
}
.custom-web-tabs.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    border-color: #fff
}
.custom-web-tabs.nav-tabs .nav-link{
    padding-right: 3rem;
    padding-left: 3rem;
    font-weight: 600;
    color: #7b7b7b;
    font-size: 1.2rem;

}
.custom-web-tabs.nav-tabs .nav-link.active{
    color: #FA5456;
    border:none;
    border-bottom: 2px solid #FA5456;
    font-weight: 600;
    background: transparent;

}

.custom-web-tabs.nav-tabs .nav-link{
    padding-right: 1rem;
    padding-left: 1rem;
    font-weight: 600;
    color: #7b7b7b;
    font-size: 1rem;
}




@media only screen and (max-width: 699px) {
    .page-title{
        padding: 1rem 1rem 0;
    }
    .custom-web-tabs.nav-tabs{
        margin-bottom: 1rem !important;
    }
}
.register_HeaderTitle__2wMct{
    color: var(--semiPurple);
    font-weight: 600;
}
.register_HeaderPargraph__3GESE{
    color: #b3b3b3;
    font-weight: 600;
}
.register_FormContainer__1CYbp{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    background: var(--babyBlue);
}

.register_FormPadding__37f1r{
    padding: 5rem 3rem;
}

.register_TopImg__38VZW{width: 300px;}

.register_FormInput__3LSEF{ 
    height: 48px;
    padding: 14px 25px;
    margin: 0px 5px;
    border-radius: 15px;
    background-color: transparent;
    color: #848484;
}

.register_togglePassword__1Iq9T{
    position: absolute;
    display: inline-block;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.register_SendCode__3g6fY{
    color: #08cad7;
    text-decoration: underline !important;
}

/* The container */
.register_CheckBoxContainer__EpcSN {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .register_CheckBoxContainer__EpcSN input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .register_Checkmark__fTvy5 {
    position: absolute;
    top: 4px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid var(--blue);
    border-radius: 4px;
  }
  
  
  /* When the checkbox is checked, add a blue background */
  .register_CheckBoxContainer__EpcSN input:checked ~ .register_Checkmark__fTvy5 {
    background-color: var(--blue);
    
  }
  
  /* Create the Checkmark/indicator (hidden when not checked) */
  .register_Checkmark__fTvy5:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the Checkmark when checked */
  .register_CheckBoxContainer__EpcSN input:checked ~ .register_Checkmark__fTvy5:after {
    display: block;
    
  }
  
  /* Style the Checkmark/indicator */
  .register_CheckBoxContainer__EpcSN .register_Checkmark__fTvy5:after {
    left: 6px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    
  }

  .register_TermsAndConditions__1NIR1{
    text-decoration: underline;
    color: var(--blue);
    font-weight: 600;
  }

  .register_InviteCode__1ZvAB{
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    padding: 2rem;
  }

  .register_InviteCode__1ZvAB h5{
    margin-bottom: 15px;
  }
  

  .register_InviteHint__3d6CA{
    color: var(--blue);
    opacity: .9;
    margin-top: 10px;
  }

@media (max-width: 768px) {
    .register_TopImg__38VZW{width: 200px;}
    .register_FormPadding__37f1r{padding: 2rem;}
}



.register_Drop__3WCfO{ 
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: #dee2e6;
}
.register_Drop__3WCfO:hover,
.register_Drop__3WCfO:active,
.register_btn__T4KDP:first-child:active,
.register_Drop__3WCfO.register_btn__T4KDP.register_show__3roWd{
  background-color:white !important;
  color: #848484 !important;
}

.CustomSelectRadio2_RadioOption__hH2kP {
  
}
.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:checked,
.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:checked + label,
.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  width: 100%;
}
.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:checked + label:before,
.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1.5px solid var(--blue);
  border-radius: 100%;
}
.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:checked + label:after,
.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--blue);
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.CustomSelectRadio2_RadioOption__hH2kP [type='radio']:checked + label:before {
  border: 1px solid var(--blue);
}

.CustomSelectRadio2_DisabledInput__GXghM {
  background: #e7e7e7;
  border: none !important;
}

.CustomSelectRadio2_DisabledInput__GXghM [type='radio']:checked + label:after,
.CustomSelectRadio2_DisabledInput__GXghM [type='radio']:not(:checked) + label:after,
.CustomSelectRadio2_DisabledInput__GXghM [type='radio']:checked + label:before,
.CustomSelectRadio2_DisabledInput__GXghM [type='radio']:not(:checked) + label:before {
  content: unset;
}

.verify_HeaderTitle__2N9Em{
    color: #44474a;
    font-weight: 600;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 5px;
}
.verify_HeaderPargraph__WH2Zu{
    color: #737373;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
}
.verify_FormContainer__3IxqN{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    background: var(--babyBlue);
}

.verify_LoginBackground__tkbCH{
    width:100%
}

.verify_FormPadding__lLGef{
    padding: 1.5rem 10rem;
}

.verify_VerifyInput__a4fiW{
    width: 60px;
    height: 70px;
    color: #848484;
    border-radius: 10px;
    background-color: transparent;
    text-align: center;
    font-size: 33px;
    font-weight: 400;
    border: 1px solid var(--Color-grey-50, #838080);

}

.verify_textCenter__2CfKg{
    cursor: pointer;
}

@media (max-width: 768px) {
    .verify_FormPadding__lLGef{padding: 2rem;}
}

.UserTrackingSystem_TrackingDetails__Gmi_U{
    padding: 0 2rem 0 0;
}
.UserTrackingSystem_ActionButtons__2QngT{
    display: flex;   
}
.UserTrackingSystem_SecondBottom__2WAy2{
    margin-left: 1rem;
}
.UserTrackingSystem_mainParagraph__1_WC5{
    color: #6e6e6e;
    font-size: 1.1rem;
}
.UserTrackingSystem_TrackVideo__Xr0M2{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
}
.UserTrackingSystem_TrackImgContainer__1o_mn,
.UserTrackingSystem_CardImgContainer__2iW7L{
    overflow: hidden;
    height: auto;
    position: relative;
    border-radius: 10px;
}

@media only screen and (max-width: 699px) {
    .UserTrackingSystem_TrackingDetails__Gmi_U{
        order: 2;
        padding: 0 1.5rem;
    }
    .UserTrackingSystem_TrackingMedia__3y7PE{
        order:1
    }
}

/*checkbox*/

  .UserTrackingSystem_AuctionType__1jNSF ul {
    list-style-type: none;
  }
  
  .UserTrackingSystem_AuctionType__1jNSF li {
    display: inline-block;
  }
  
  .UserTrackingSystem_AuctionType__1jNSF input[type="radio"][id^="name"] {
    display: none;
  }
  
  .UserTrackingSystem_AuctionType__1jNSF label {
    display: block;
    position: relative;
    margin: 10px 20px;
    cursor: pointer;
    
  
  }
  
  .UserTrackingSystem_AuctionType__1jNSF label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #FA5456;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .UserTrackingSystem_AuctionType__1jNSF label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  .UserTrackingSystem_AuctionType__1jNSF :checked + label img {
    border: 2px solid #FA5456;
    padding: 3px
  }

  .UserTrackingSystem_AuctionType__1jNSF :checked + label h6 {
    color: #FA5456;
  }
  
  .UserTrackingSystem_AuctionType__1jNSF :checked + label:before {
    
  }
  
  .UserTrackingSystem_AuctionType__1jNSF :checked + label img {
    z-index: -1;
  }
/* VideoPlayer.css */

.video-player {
    position: relative;
    width: 100%;
    max-width: 800px; /* Adjust as needed */
  }
  
  .video-player video {
    width: 100%;
    display: block;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and opacity */
  }
  
  .overlay img {
    cursor: pointer;
  }
  
  .overlay img:hover {
    opacity: 0.8; /* Adjust the hover opacity of the play button */
  }
.font-weight-400{
  font-weight: 400;
}
.font-weight-500{
  font-weight: 500;
}
.font-weight-600{
  font-weight: 600;
}
.font-weight-700{
  font-weight: 700;
}
.font-12{
  font-size: 12px;
}
.font-13{
  font-size: 13px;
}
.font-14{
  font-size: 14px;
}
.font-16{
  font-size: 16px;
}
.font-20{
  font-size: 20px;
}
.font-22{
  font-size: 22px;
}
.font-24{
  font-size: 24px;
}
.font-26{
  font-size: 24px;
}
.text-gray-100{
  color: #636363;
}
.text-gray-200{
  color: #A09F9F;
}
.text-gray-300{
  color: #5B555C
}
.text-gray-400{
  color: #757575
}

.flex-1{
  flex: 1 1;
}
section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.top-p{
  font-size: 20px;
    color: #000000 !important;
    font-weight: 100;
}
.see-more{
    text-decoration:none;
    font-weight: 400;
    font-size: 16px;
    color: var(--semiPurple);
}
.text-right {
  text-align: right;
}
.flex-grow-1{
  flex-grow: 1;
}
.main-color {
  color: #FA5456;
}
.red-color{
  color:#ED5859;
}
.semi-red-color{
  color: #FF9DCC
}
.blue-color{
  color: #07b2bd;
}
.ar-dir{
  direction: rtl;
  text-align: right;
}
.answer-label{
  margin: 0px 5px;
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    padding: 10px 2.8rem;
    cursor: pointer;
}
.answer-label-radio{
  padding: 0 2rem !important;
}
.main-icon{
    background: #e9fcf8;
    border-radius: 5px;
    padding: 10px 12px 10px 10px;
    position: relative;
    cursor: pointer;
}
.secondry-color {
  color: #FA5456;
}
.main-title {
  color: #08cad7;
  font-weight: 600;
}
.danger-title {
  color: #FA5456;
  font-weight: 600;
}
.main-black-title {
  font-weight: bold;
  color: #6e6e6e;
  margin-bottom: 15px;
}
.main-margin-top {
  margin-top: 5rem;
}
.text-font-muted {
  color: #b3b3b3;
  font-weight: 500;
}
.text-muted {
  color: #b3b3b3;
  font-weight: 500;
}
.font-weight-bold {
  font-weight: bold;
}
.main-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  margin-bottom: 25px;
  border: none;
}
.bg-Progress-Success {
  background-color: #07b2bd !important;
}
.bg-Progress-Failed {
  background-color: #FA5456 !important;
}
.bg-Progress-Improve {
  background-color: #ffb448 !important;
}
.bg-blue{
  background: var(--blue);
}
.bg-purple{
  background-color: var(--semiPurple);
}
.bg-red{
  background-color: #E80D44;
}
.card {
  border-radius: 8px !important;
}
.card .card-img-top {
  border-radius: 8px !important;
}

.img-input-placeholder{
    position: absolute;
    display: inline-block;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}

/* modal */
.modal-header {
  border-bottom: 0;
}
.modal-footer {
  border-top: 0;
}

a {
  text-decoration: none;
}

/* nav tabs */
.nav-tabs .nav-item {
  margin-bottom: 5px;
}
.consultant-tabs.nav-tabs .nav-item{
  margin-bottom: 0;
}

body > iframe {
  display: none;
}

@media (min-width: 769px) {
  .lesson-type-feature{
    padding-left: 2rem;
  }
  .card-item-container{
    padding: 0;
    margin-bottom: 20px;
  }
  /*
  .card-item-container .card-body{
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
  */
  /*
  .list-item-card,
  .card-item-container > div{
    height: 100%;
  }
  */
  /*
  .card-item-container > div{
    width: calc(100% - 40px);
  }
  */
  .main-course-card:hover .description{
    display: block !important;
  }
  .main-course-card:hover{
    height: 290px;
  }
  .main-course-card:hover img,
  .main-course-card:hover .card-custom-provider,
  .main-course-card:hover .course-custom-price-label,
  .main-course-card:hover .top-card-label,
  .main-course-card:hover .course-card-title
  {
    display: none !important;
  }
  .mentor.consultant-card:hover .about{
    display: block !important;
  }
  .mentor.consultant-card:hover{
    height: 320px;
  }
  .mentor.consultant-card:hover img,
  .mentor.consultant-card:hover .availabitily-1,
  .mentor.consultant-card:hover .availabitily-0,
  .mentor.consultant-card:hover .card-custom-title,
  .mentor.consultant-card:hover .cons-langs,
  .mentor.consultant-card:hover .top-card-label > span{
    display: none !important;
  }
  .card-item-container:nth-child(3n) > div { 
    margin-left: auto;
  }
  .card-item-container:nth-child(3n + 1) > div {
    margin-right: auto;
  }

  .card-item-container:nth-child(3n - 1) > div {
    margin: auto;
  }
  
  .get-in-touch{
    padding-top: 7rem ;
    padding-bottom: 7rem ;
  }
  .get-in-touch a{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 15px 50px;
    text-align: initial;
    margin: initial;
    background: var(--semiPurple);
  }
  .home-banner img{
    height: 604px;
  }
  .home-banner.pop-up img{
    height: auto;
  }
  .courses-list .course-image img{
    width: 180px;
    height: 160px
  }
  .modal-dialog {
    min-width: 560px;
  }
  .hideScroll {
    overflow-x: auto;
    white-space: nowrap;
  }
}

.allSessionsContainer::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.allSessionsContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}
.allSessionsContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(left, #999, #888);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
}
/*calender style*/
.rmdp-container {
  position: relative;
}
.rmdp-input {
  border-radius: 15px;
  height: 45px;
  padding: 0 1.5rem;
  width: 125%;
  background: transparent;
  color: transparent;
  border: none;
}
.rmdp-input:focus {
  border: none;
  box-shadow: none;
}

/*drop down*/
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: white !important;
  color: #848484 !important;
  border-color: var(--bs-btn-active-border-color);
}

.dropdown-toggle::after {
  right: 2rem;
  position: absolute;
}

.dropdown-menu.show {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  max-height: 300px;
  overflow-y: auto;
}
.share-dropdown .dropdown-menu.show{
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.date-dropdown .dropdown-menu.show {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.dropdown .form-control:focus {
  box-shadow: none;
  border-color: #fff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fff;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
  color: #848484;
  padding: 14px 25px;
  border-radius: 10px;
  border-color: #e9ecef;
}
.css-13cymwt-control {
  height: 48px;
  margin: 0px 5px;
  border-radius: 10px !important;
  background-color: #f5f5f5 !important;
  border: none;
  color: #848484;
  padding: 5px 5px 15px 15px;
  border-color: transparent;
  border: none !important;
}

.css-1p3m7a8-multiValue {
  background-color: #fed21a !important;
  border-radius: 5px !important;
  padding: 2px 5px !important;
  color: #000 !important;
  border-color: none !important;
}

.css-12a83d4-MultiValueRemove:hover {
  background-color: #fed21a !important;
  color: #000 !important;
}
.hideScroll {
  padding-bottom: 10px;
}
.hideScroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.hideScroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}
.hideScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #FA5456;
}
.notifications-box::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.notifications-box::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}
.notifications-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #FA5456;
}

.box-border {
  padding: 1rem;
  border: 1px solid #cfcfcf;
  border-radius: 15px;
}

.courses-list{
  margin: 10px;
}
.course-rating{
  display: block !important;
}
.rate-number{
  margin: 0 !important;
}
.courses-list .course-item .course-price{
  padding: 0 !important;
  text-align: left;
}
.courses-list .course-item{
  padding: 0.5rem 1rem;
}
.courses-list .course-details{
  padding: 0 10px !important;
}
.courses-list .course-item h4{
  margin: 10px 0 20px;
  padding: 0;
  
}
.show-sidebar-icon{
  display: none;
}
.top-user-icons{
  display: none;
}

.top-user-icons .dropdown-menu {
  width: 300px;
  right: 0;
  left: auto;
}
.top-user-icons #dropdown-basic{
  border: none !important;
    background: #fff;
}
.top-user-icons .dropdown-toggle::after {
  border: 0;
}
.achievement-container{
  justify-content: space-evenly;
}


@media (max-width: 768px) {
  .share-dropdown{
    flex-direction: column;
  }
  .notifications-box{
    right: -8.5rem !important;
    width: 350px !important;
  }
  .top-web-icon{
    display: none !important;
  }
  .top-user-icons{
    display: block;
  }
  .show-sidebar-icon{
    display: block;
  }
  .courses-feedback{
    padding: 0 10px
  }
  .mobile-hidden{
    display: none;
  }
  .courses-list .course-item h4{
    
    font-size: 17px;
  }
  .courses-list .course-image{
    margin: 10px 10px 0px 0px
  }
  .courses-list .course-image img{
    width: 100px;
    height: 100px
  }
  .custom-tab-button{
    font-size: 15px !important;
    margin-bottom: 1rem !important;
  }
  .join-consultants{
    text-align: center;
  }
  .join-consultants button{
    margin-bottom: 2rem;
  }
  .hideScroll {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .hideScroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }
  .hideScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparent;
  }
  .achievement-container{
    justify-content: normal;
  }
  .achievement-card{
    margin: 10px 10px 0 10px !important;
  } 
  .achievement-card h4{
    width: 200px;
  }

  .availablity-container{
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .availablity-container > div{
    flex: 1 0 auto;
  }
  .availablity-container > div:nth-child(1){
    flex-basis: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
  }
  .availablity-container > div:nth-child(2){
    display: none;
  }
  .availablity-container > div:nth-child(3),
  .availablity-container > div:nth-child(4){
    flex-basis: 50%;
  }
  
}
.cursor-pointer,
.rmdp-input{
  cursor: pointer;
}
.red{
  color: red;
}
.card .card-img-top,
.mentor.card .card-img-top {
  height: 175px;
}
.event-card-details.card .card-img-top {
  height: auto;
}
.form-check{
  margin-bottom: .5rem;
}


@media (max-width:669px) {
  .top-head-sidebar {
      margin-bottom: 1rem;
  }
  .user-profile-side-bar{
      z-index: 4;
  }
  .all-user-profile-tabs{
    flex-wrap: nowrap;
    overflow-x: scroll;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0;
    justify-content: start !important;
  }
  .consultant-pages .nav-link {
    padding: 20px 10px 20px;
  }
  .ranking-name{
    font-size: 15px !important;
  }
  .top-head-consultant{
    display: none;
  }
  .header{
    height: auto !important;
    padding: 20px 0;
    margin-bottom: 1rem !important;
  }
  .consultant-card{
    min-height: auto;
    margin-top: 20px;
  }
  .consultant-tabs{
    overflow: scroll;
    flex-wrap: nowrap;
    margin-bottom: 0rem !important;
  }
  .ConsultantTabsContainer .tab-content{
    padding: 1rem;
  }
  .consultant-tabs li{
    white-space: nowrap;
  }
  .consultant-tabs::-webkit-scrollbar {
    width: 0px;
  }
  .comming-soon{
    padding: 0 !important;
  }
  .comming-soon h1{
    font-size: 30px !important;
  }
  .comming-soon .main-head{
    margin-top: 10px !important;
  }
  .comming-soon .main-head p{
    font-size: 22px !important;
  }
  .comming-soon .main-head p:last-child {
    font-size: 16px !important;
}
  .store-icons{
    display: flex;
    justify-content: center;
  }
}
.social-popup iframe{
  width: 100%;
}
.my-courses-list .search-container{
  display: none;
}
.activity-icon{
  margin: 0 20px 0 0;
}
.ar-dir-container .activity-icon{
  margin: 0 0 0 20px;
} 
.Filter{
  width: 50px;
  background: #e9fcf8;
  border-radius: 10px;
  padding: 10px 12px 10px 10px;
  position: relative;
  cursor: pointer;
}
.react-tel-input > input{
    height: 48px !important;
    padding: 14px 40x !important;
    margin: 0px 5px !important;
    border-radius: 15px !important;
    background-color: #fff !important;
    border: none !important;
    color: #848484 !important;
    width: 100% !important;
}
.position-relative{
  position: relative;
}
.top-label{
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  border-radius: 0px 10px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #07b2bd;
  padding: 3px 10px;
}
.achievement-card{
    padding: 2rem;
    background: #E1F4FF;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 40px;
    width: 250px;
}
.achievement-card .ach-number{
  font-size: 26px;
  font-weight: 700;
}
.achievement-card .go{
  width: 25px;
}
.achievement-card.challanges{
  background: #E1F4FF;
}
.achievement-card.challanges h4,
.achievement-card.challanges .ach-number{
  color: #008993;
}


.achievement-card.follow-up{
  background: #FFF4E8;
}
.achievement-card.follow-up h4,
.achievement-card.follow-up .ach-number{
  color: #FF9B26;
}

.achievement-card.courses{
  background: #FFEFF0;
}
.achievement-card.courses h4,
.achievement-card.courses .ach-number{
  color: #FA5456;
}

.achievement-card.sessions{
  background: #FDF4FF;
}
.achievement-card.sessions h4,
.achievement-card.sessions .ach-number{
  color: #72048B;
}

.achievement-card.invitation{
  background: #F9F9F9;
}
.achievement-card.invitation h4,
.achievement-card.invitation .ach-number{
  color: #5B5B5B;
}

.home-banner .carousel-indicators button{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
}

.home-banner .carousel-indicators .active{
  background-color: #FA5456;
}
.containers-tab .nav-tabs .nav-item{
  flex-grow: 1;
}

.containers-tab .nav-tabs .nav-item button{
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}

.containers-tab .nav-tabs .nav-item button svg{
  margin: 0 5px;
  vertical-align: bottom;
}

.containers-tab .custom-web-tabs.nav-tabs .nav-link{
  border: none;
  border-radius: 5px;
  color: #B0B0B0;
  background: #fff;
  margin-right: 10px;
  width: calc(100% - 10px);
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containers-tab .custom-web-tabs.nav-tabs .nav-link.active{
  background: #FA5456 ;
  color: #fff ; 
}

.child-tabs button.active{
  
  background: #00759614 !important;
  color: #008993 !important;
}

.rank-details{
  padding: 20px;
  border-radius: 10px;
}

.first-rank{
  background: #FFFCE2;
  color: #3C69BE;
}

.second-rank{
  background: #E3F6FF;
  color: #3C69BE;
}

.third-rank{
  background: #ECF4FF;
  color: #3C69BE;
}

.normal-rank{
  background:#F4F4F4;
  color: #9B9B9B;
  padding: 30px 27px !important;
}

.rank{
  
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
}

.info-img{
  width: 20px; height: 20px; cursor: pointer;
}
.invite-desc{
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  padding: 10px;
}



.home-custom-card{
  box-shadow: none;
  border-radius: 5px !important;
  overflow: hidden;

}
.card-custom-body{
  background-color: #f9f9f9;
  padding-top: 20px;
}
.subscribed-label{
  background-color: #FAD01C;
  color: #262626;
  font-weight: 600;
  font-size: 12px;
  border-radius: 8px;
  padding: 5px 15px;
  margin: 0 10px;
  
}

.price-label{
  background-color: #FF9640;
  color: #F5F5F5;
  font-weight: 600;
  font-size: 12px;
  border-radius: 8px;
  padding: 5px 15px;
  text-transform: capitalize;
}

.price-course-label{
  color: #F5F5F5;
  font-weight: 600;
  font-size: 12px;
  border-radius: 8px;
  padding: 5px 15px;
  text-transform: capitalize;
}


.type-label{
  background-color: var(--maincolor);
  color: #F5F5F5;
  font-weight: 500;
  font-size: 14px;
  border-radius: 26px;
  padding: 5px 15px;
  text-transform: capitalize;
}

.top-card-label{
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}
.top-card-label > span{
  margin-bottom: 10px;
  display: inline-block;
}
.top-card-label > img{
  margin-left: auto;
}
.card-custom-title{
  color: #1C0A26;
  font-weight: 600;
  font-size: 16px;
}
.card-custom-price{
  color: #FF5B5B;
  font-size: 18px;
  font-weight: 700;
}
.course-custom-price-label{
  font-weight: 600;
    border-radius: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px;
    color: #FFF;
    font-size: 12px;
}
.orange-bg{
  background: #FF9640;
}
.blue-bg{
  background: #1BCDDB;
}
.semi-red-bg{
  background:#FF9DCC;
}
.gray-bg-2{
  background: #A9A9A9;
}
.card-custom-provider{
  color: #1C0A26;
  font-weight: 400;
  font-size: 13px;
  margin: 10px 0;
}
.card-custom-date{
  color: #787878;
  font-size: 14px;
  margin: 0 5px;
}
.card-custom-specialize{
  border-radius: 8px;
  background: rgba(203, 203, 203, 0.30);
  color: #848484;
  font-size: 13px;
  font-weight: 400;
  padding: 8px;
}
.card-custom-button{
  background: var(--blue);
  border-radius: 33px;
  color: #fff;
  padding: 15px;
  text-align: center;
  margin: 10px auto 0;
  width: 100%;
  border: none;
  font-size: 18px;
  font-weight: 500;
}
.card-lang{
  color:#FF5B5B;
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px;
}
.react-multi-carousel-dot button{
  border: none !important;
}
.react-multi-carousel-dot--active button{
    background: #FA5456 !important;
    border: none;
    width: 35px !important;
    border-radius: 6px !important;
}

.react-multi-carousel-track{
  margin-bottom: 40px !important;
}
.get-in-touch{
  /*background: linear-gradient(0, #F7F7F7, #FFFBDC);*/
  background: #FFF1E6;
}

.track-name{
  font-size: 27px;
  font-weight: 600;
  color: #070707;
  margin: 0;
}
.track-provider{
  background: #00759614;
  color: #008993;
  border-radius: 29px;
  padding: 5px 15px;
  margin: 0 10px;
}
.track-desc-title{
  font-size: 20px;
  font-weight: 600;
  color: #070707;
}
.track-desc-content{
  font-size: 16px;
  font-weight: 300;
  color: #000000;
}
.track-see-more{
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  margin: 0 5px;
}

.act-day-number{
  font-size: 18px;
  font-weight: 500;
}
.act-day-name{
  font-size: 12px;
  font-weight: 500;
  color: #949199;
  text-transform: uppercase;
}
.act-day-name.active{
  color: #fff !important;
}
.points-title{
  font-size: 18px;
  font-weight: 400;
  color: #070707;
}
.points-val{
  color: #FF5B5B;
  font-weight: 700;
}
.shareDropdown #dropdown-custom{
  padding: 0;
}
.Sign-in .card-button{
  background: var(--blue);
  border-color: #fff;
  border-radius: 8px;
  font-weight: 700;
}
.contact-header{
  height: 220px;
  background-color: #C3FBFF;
}
.contact-header .breadcrumb-header{
  font-size: 33px;
}

.contact-header .breadcrumb-details span{
  font-size: 16px;
  font-weight: 300;
  color: #787878;
}

.contact-item p{
text-align: left;
margin: 0;
color: #2E4450;
font-weight: 500;
}

#filterContainer{
  display: none;
}
.top-list-filters{
  background: #1BCDDB;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
}
.top-filter-type{
  font-weight: 400;
  font-size: 15px;
  color: #F9F9F9;
}
.top-list-filters .btn-check:checked + .btn,
.top-list-filters .btn.active,
.top-list-filters .btn.show,
.top-list-filters .btn:first-child:active,.top-list-filters :not(.btn-check) + .btn:active{
  background-color: transparent !important;
  border: none;
}

.top-list-filters .filter-type-options{
  padding: 10px 10px 0;
}

.filter-type-options .custom-input{
  width: 100%;
}

.search-icon{
  cursor: pointer;
}
.submit-date{
    background-color: #FA5456;
    color: #F5F5F5;
    font-weight: 500;
    font-size: 14px;
    border-radius: 26px;
    padding: 0px 15px;
    text-transform: capitalize;
    border: none;
    margin: 0 5px;
}
.rate-course-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fed21a;
    padding: 10px 30px;
    border-radius: 20px;
    font-weight: 600;
}




@media (max-width: 768px) {
  .top-list-filters{
    margin-left: 1px;
    margin-right: 1px;
  }
  .top-filter-container{
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
  }
  .top-filter-type {
      font-size: 13px;
  }
  .get-in-touch{
    padding: 1rem;
  }
  .get-in-touch p{
    margin-bottom: 15px;
  }
  .get-in-touch a{
    margin-bottom: 15px;
    display: block;
  }

}
.ql-hidden{
  display: none;
}

.consultnant-profile-card .card-item-container>div, .consultnant-profile-card .list-item-card{
  height: auto;
}

.form-check-input:checked {
  background-color: #FA5456;
  border-color: #FA5456;
}
.submitted-at{
  font-size: 13px; font-weight: 300; color: rgb(120, 120, 120);
}

/*custom tabs*/
.containers-tab .nav-item{
  flex-grow: 1;
}
.containers-tab .nav-link{
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: #B0B0B0;
  background: #fff;
  margin-right: 10px;
  width: calc(100% - 10px);
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
    font-weight: 500;
}
.containers-tab .nav-link.active {
  background: rgba(235, 113, 0, 0.1) !important;
  color: #EB7100 !important;
  font-weight: 600;
}
/*custom tabs/

/*quiz*/
.quiz-title{
  font-size: 20px;
  display: flex;
  justify-content: center;
  color: var(--maincolor);
  font-weight: 700;
}
.quiz-desc{
  color: #3E3E3E;
  font-size: 15px;
  font-weight: 400;
}
.quiz-notes li{
  color: #3E3E3E;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 1px solid #E9E9E9;
  padding: 10px 0px;
}
.quiz-quetion-header{
  cursor: pointer;
}
.choose-answer{
  color: #959595;
  font-size: 15px;
  font-weight: 400;
}
.quiz-quetion{
  color: #3E3E3E;
  font-size: 20px;
  font-weight: 500;
}
.quiz-popup-title{
  color: var(--Grey, #4F4F4F);
  font-size: 20px;
  font-weight: 700;
}
.quiz-popup-desc{
  color: var(--Color-Grey-200, #A3A3A3);
  font-size: 14px;
  font-weight: 400;
}
.quiz-success-title{
  color: #4ACD7F;
  font-weight: 400;
}
.quiz-fail-title{
  color: #F51E21;
  font-weight: 400;
}
.final-correct{
  background: #fff !important;
  color: #4ACD7F !important;
  border: 1px solid #4ACD7F !important;
}
.final-wrong{
  background: #fff !important;
  color: #F51E21 !important;
  border: 1px solid #F51E21 !important;
}
.user-placeholder{
    border-radius: 8px;
    background: #E5F9FF;
    padding: 2px 5px;
}
.gray-bg{
  background: #fafafa
}

.quiz-container p{
  margin-bottom: 1rem;
}
.result-show{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 0 1rem;
}

/*quiz*/

.user-cart-data{
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #898989;
}


.family-user{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #ECECEC;
  padding: 15px 25px;
  border-radius: 8px;
  margin-right: 10px;
  background: #f9f9f9;
}
.family-user.active{
  background: #fff; 
}

.family-user .user-img{
  width: 45px;
  height: 45px;
  margin: 0 10px 5px;
  border-radius: 50%;
}

.family-user span{
  color: #464646;
  font-size: 17px;
  font-weight: 400;
}

.family-user p{
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0 0 0;
  color: #8B8B8B;
}

.asign-user{
  color: #FF5659 !important;
}

.popup-header{
  font-size: 20px;
  font-weight: 700;
  color: #464646;
  margin: 15px 0;
}

.package-levels{
  padding-left: 10px;
  border-left: 1px solid #8B8B8BB2;
  margin: 0 10px;
}

.package-levels span{
  margin: 0 5px;
}

.popup-desc{
  color: #989898;
  font-weight: 400;
  font-size: 17px;
  width: 90%;
  margin: auto;
}

.challenge-cart-label{
  color:#848484;
  font-weight: 400;
  font-size: 13px;

}
.book-level-btn{
  border-radius: 8px;
  border: 1px solid var(--Primary, #FF5659);
  background: rgba(255, 86, 89, 0.10);
  padding: 10px;
}

.booked-level-btn{
  border-radius: 8px;
  border: 1px solid rgba(74, 205, 127, 0.40);
  background: rgba(74, 205, 127, 0.20);
  padding: 10px;
}

.ql-editor{
  padding: 0 !important;
}
.normal-tab{
  border: none;
    border-radius: 5px;
    color: #B0B0B0;
    background: #fff;
    margin-right: 10px;
    width: calc(100% - 10px);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;;
}

.active-tab{
  background: rgba(235, 113, 0, 0.1) !important;
  color: #EB7100 !important;
}

.card{
  box-shadow: none !important;
}
.consultant-card{
  background: #F5F5F5;
}
.availabitily-1,.availabitily-0{
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  padding: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.availabitily-0{
  background: #FA6163;
}
.availabitily-1{
  background: #1EE7AE;
}
.carbon_password{
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
.consultant-actions-btns{
  border-radius: 8px;
}
.consultant-features{
  font-size: 14px;
  color: #1C0A26;
}
.consultant-features .value {
  font-weight: 600;
}
.react-calendar__navigation .react-calendar__navigation__label{
  font-weight: 500;
  font-family: Montserrat;
}
.react-calendar__navigation__arrow{
  font-size: 30px;
}
.progress-bar .progress{
  --bs-progress-bar-bg: #FF9640;
}
.lang-feature{
  border-radius: 8px;
  background: rgba(203, 203, 203, 0.30);
  color: #848484;
  font-size: 13px;
  font-weight: 400;
  padding: 7px;
}
.slot-box{
  width: calc(100% - 0px);
}
/*rtl*/
.not-available-title{
  margin-top: 15px;
  color: #FF5B5B;
  font-weight: bold;
}
body[lang="ar"]{
  direction: rtl;
}
body[lang="ar"] .activity-icon{
  margin: 0 0 0 20px;
}
/*rtl*/
.login-link{
  text-decoration: underline;
  color: var(--blue);
  font-weight: 600;
}

.StatusButton_Status__1v94_{
    padding: 10px 40px;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
  }
.initiative-card{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
    cursor: pointer;
}
.initiative-card-title {
    font-weight: bold;
    font-size: 18px;
    color: #7b7b7b;
}
.initiative-provider{
    color: #08cad7;
    font-weight: bold;
}
.initiative-rate .rate-stars{
    margin: 0;
}
.initiative-provider{
    color: #08cad7;
    font-weight: bold;
}
.Price{
    color: #FA5456;
    font-size: 1.1rem;
    font-weight: bold;
}
.Date{
    border-top: 1px solid rgb(238, 238, 238);
    padding-top: 15px;
}
.TrackingSystemCard_Image__rqLf6{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 8px 24px 0 rgba(183, 183, 183, 0.17);
    background-color: #fff;
    margin: 2rem auto 0rem;
}


.TrackingSystemCard_Card__20Dgb {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
  }
  
  .TrackingSystemCard_Card__20Dgb .TrackingSystemCard_Percent__wvGdc {
    position: relative;
  }
  
  .TrackingSystemCard_Card__20Dgb svg {
    position: relative;
    width: 70px;
    height: 70px;
    transform: rotate(-90deg);
  }
  
  .TrackingSystemCard_Card__20Dgb svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #f0f0f0;
    stroke-width: 5;
    stroke-linecap: round;
  }
  
  .TrackingSystemCard_Card__20Dgb svg circle:last-of-type {
    stroke-dasharray: 208px;
    stroke-dashoffset: calc(208px - (208px * var(--percent)) / 100);
    stroke: #FA5456; 
  }
  
  .TrackingSystemCard_Card__20Dgb .TrackingSystemCard_Number__3cx3Y {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .TrackingSystemCard_Card__20Dgb .TrackingSystemCard_Number__3cx3Y h3 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }
  
  .TrackingSystemCard_Card__20Dgb .TrackingSystemCard_Number__3cx3Y h3 span {
    font-size: 1rem;
  }
  
  .TrackingSystemCard_Card__20Dgb .TrackingSystemCard_title__Somzr h2 {
    margin: 25px 0 0;
  }
  
  .TrackingSystemCard_Card__20Dgb:nth-child(2) svg circle:last-of-type {
    stroke: #2ecc71;
  }

  .TrackingSystemCard_Type__eakP8{
    margin-left: 10px;
    color: #fed21a;
    font-size: 1.3rem;
    font-weight: 600;
  }

.PersonalityCard_Card__243Ej{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
}
.PersonalityCard_TestName__1Me3e{
    color: #434343;
    font-weight: 600;
}
.PersonalityCard_Icon__1HlHx{
    background-color: #07b2bd;
    border-radius: 10px;
    padding: 10px 15px;
}

.PrimaryButton_Button__1wlO9{
    text-align: center;
    background-color: #FA5456;
    outline: none;
    border: none !important;
    border-radius: 15px;
    color: #434343;
    padding: .5rem 2rem;
}
.BankQuestions_bankQuestionSearch__3bui5{
    background-color: #f7f7f7;
    border: 0;
    padding: 15px 20px;
}
.courses-top-head{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;
}
.sort-container{
    margin-left: auto !important;
}
.course-title,
.course-header h5{
    font-weight: bold;
    color: #6e6e6e;
    
}
.course-header h5{
    margin-bottom: 2px;
}
.course-header p{
    color: #b3b3b3;
    font-weight: 500;
}
.course-description{
    color: #b3b3b3;
    font-weight: 500;
}
.course-provider{
    color: #08cad7;
    font-weight: bold;
    margin-bottom: 15px;
}

.course-content-sections{
    padding-right: 30px;
}

.section-container .collapse{
    padding: 10px 20px 10px 0;
}

.section-item-collapse{
    margin: 15px 0 15px 0px;
    padding: 15px;
    border-radius: 9px;
    box-shadow: 0 8px 24px 0 rgba(183, 183, 183, 0.17);
    background-color: #fff;
}

.section-title{
    margin-top: 0;
    margin-bottom: 0;
}

.section-item {
    align-items: flex-start;
    margin: 20px 0;
}

.section-item p{
    margin-bottom: 0;
}
.question-icon{
    /* padding: 10px 15px 15px; */
    margin-right: 10px;
    border-radius: 15px;
    background-color: none;
    
}
.question-icon img{
    width: 35px;
}
.lock-icon{
    border-radius: 7px;
    color: #FA5456;
    font-size: 1.2rem;
}

.lecture-type{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}

.bankquestionItem .collaped-item svg{
    border-radius: 5px;
    background-color: #FA5456;
    color: #fff;
    padding: 5px;
    font-size: 25px;
}
.bankQuestionRates{
    color: #000;
    font-size: 20px;
}

.bankQuestionRates img{
    vertical-align: text-bottom;
}


@media (max-width: 768px) {
    .bankquestionItem.section-item-collapse{
        position: relative;
    }
    .bankquestionItem.section-item-collapse,
    .bankquestionItem .first-section{
        flex-direction: column;
        align-items: start !important;
    }
    .bankquestionItem .section-title{
        margin-top: 5px;
    }
    .bankquestionItem .question-icon img{
        margin-top: 10px;
    }
    .bankquestionItem .collaped-item{
        position: absolute;
        top: 1.2rem;
        right: 0.5rem;
    }
    .bankQuestionRates > div{
        display: flex;
        margin: 0 0 5px 5px !important;
        align-items: center;
    }
    .bankQuestionRates img{
        width: 15px;
        height: 15px;
    }
    .bankQuestionRates span{
        font-size: 17px;
    }
}

@media (min-width:669px) {
    .search-button{
        right: 15px !important;
    }
}
.login_HeaderTitle__2zcAE {
  margin: 1rem 0 2rem;
  text-align: center;
  border-bottom: 1px solid #e3e3e3;
  line-height: 0.1em;
}

.login_HeaderTitle__2zcAE span {
  background: #fff;
  padding: 0 10px;
}

.login_HeaderPargraph__adPHQ {
  color: #b3b3b3;
  font-weight: 600;
}
.login_FormContainer__3si57 {
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  
}

.login_FormPadding__Nm08J {
  padding: 2rem 2rem;
  background: #fff;
  border-radius: 8px;
}

.login_VerifyInput__14zGy,
.login_EmailInput__1rBta,
.login_PasswordInput__ZitkY {
  height: 48px;
  padding: 14px 45px;
  margin: 0px 5px;
  border: 1px solid #192252 !important;
  border-radius: 8px !important;
  border: none;
  color: #848484;
}

.login_forgetPassword__3zHJ9 {
  text-align: center;
  margin-top: 15px;
  font-size: 17px;
  text-decoration: underline;
  color: #161313;
  font-weight: 400;
}
.login_CreateOne__3GSMh {
  text-align: center;
  font-weight: 500;
  display: inline-block;
  margin-top: 1rem;
  color: var(--blue) !important;
  text-decoration: underline;

}
.login_togglePassword__1xpsp {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 10px;
}

.login_GoogleSignIn__uAQR0,
.login_LoginOption__2uEM3 {
    padding: 14px 25px;
    border-radius: 8px;
    background-color: #fff;
    color: #3E3E3E;
    width: 100%;
    display: flex;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    margin-top: 5px;
    justify-content: center;
    font-size: 17px;
    border: 1px solid #192252 !important;
    margin-top: 20px;
}

.login_GoogleSignIn__uAQR0 img,
.login_LoginOption__2uEM3 img {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .login_FormPadding__Nm08J {
    padding: 2rem;
  }
}
.login_error__1VbcQ {
  color: red;
  margin-top: 10px;
  margin-left: 10px;
}
.login_SignTitle__aLhiK{
  font-size: 30px;
  color: #101826;
  font-weight: 700;
}
.login_GrayTitle__1lrys{
  font-size: 16px;
  font-weight: 400;
  color: #868686;
}
.UserPersonalityResult_PersonalityResults__2rtTt{
    border-radius: 15px;
    background-color: #f8f8f8;
    padding: 15px 30px;
    margin-top: 3rem;
}
.UserPersonalityResult_ResultTitle__18TiO{
    padding: 25px 15px 5px;
    font-weight: 600;
    color: #434343;
    font-size: 22px;
}
.PersonalityTest_personalityTest__3LRcU{
    padding-top: 10rem;
}
.PersonalityTest_PersonalityTestDesc__SxC02{
    max-width: 500px;
    margin: auto;
}
.PersonalityTest_QuestionNumber__kTVHJ{
    font-weight: bold;
}
.PersonalityTestQuestion_personalityTest__yL2SQ{
    padding-top: 5rem;
}

.PersonalityTestQuestion_Questions__PwFJX [type="radio"]:checked,
.PersonalityTestQuestion_Questions__PwFJX [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.PersonalityTestQuestion_Questions__PwFJX [type="radio"]:checked + label,
.PersonalityTestQuestion_Questions__PwFJX [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.PersonalityTestQuestion_Questions__PwFJX [type="radio"]:checked + label:before,
.PersonalityTestQuestion_Questions__PwFJX [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.PersonalityTestQuestion_Questions__PwFJX [type="radio"]:checked + label:after,
.PersonalityTestQuestion_Questions__PwFJX [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #FA5456;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.PersonalityTestQuestion_Questions__PwFJX [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}
.PersonalityTestQuestion_Questions__PwFJX [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}
.PersonalityTestQuestion_Question__cYae9{
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 2.5px solid #eee;
    padding: 1rem 2rem;
    border-radius: 15px;
    margin-bottom: 20px;
}
.PersonalityTestQuestion_QuestionFooter__sHXv8{
    border: solid 1px #ddd;
    background-color: #f8f8f8;
    padding: 40px;
}

.PersonalityTestQuestion_NextButton__3cTUW{
    text-align: center;
    width: 100%;
    background-color: #FA5456;
    outline: none;
    border: 2px solid #FA5456;
    border-radius: 15px;
    padding: 10px 35px;
    font-weight: 600;
    font-size: 1.2rem;
    
}
.PersonalityTestQuestion_NextButton__3cTUW:hover,
.PersonalityTestQuestion_NextButton__3cTUW:active{
    background-color: #FA5456 !important; 
    border: 2px solid #FA5456;
}

.PersonalityTestQuestion_NextButton__3cTUW:disabled{
    background-color: #919191 !important;
    border: 2px solid #919191;
    color: #fff;
    font-size: 1.2rem;
}

.BankQuestionForm_BankQuestionForm__QJIor{
    padding-top: 5rem;
}
.BankQuestionForm_BankQuestionFormDesc__3K3T8{
    max-width: 500px;
    margin: auto;
}
.BankQuestionForm_QuestionTextarea__2cNYc{
    padding: 10px 20px;
    border-radius: 15px;
    border: solid 1px #efefef;
    background-color: #fafafa;
}
.header{
    height: 220px;
    background-color: #f8f8f8;
    margin-bottom: 3rem;
}

.breadcrumb{
    flex-direction: column;
}

.breadcrumb-header{
    color:#07b2bd;
    font-weight: 600;
}

.breadcrumb-details span{
    color: #a1a1a1;
    font-weight: 600;
}

.breadcrumb-details span.active-page {
    color:#08cad7;
}
.filters-title,
.filter-type-title{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;;
}
.filter-content{
    border-bottom: 2px solid #eee;
    padding: 20px 0;
}

.filter-content:last-child{
    border-bottom: none
}

.form-check label{
    color: #a1a1a1 !important;
    font-weight: 500;
}
.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked{
    background-color: #FA5456 !important;
    border-color: #FA5456 !important;
}

.form-check-input:active {
    filter: brightness(100%) !important;
  }

.courses-top-head{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;
}
.sort-container{
    margin-left: auto !important;
}
.select-box {
position: relative;
display: block;
width: 100%;
font-size: 16px;
color: #bfbfbf;
}

.select-box__current {
position: relative;
cursor: pointer;
outline: none;
}
.select-box__current:focus + .select-box__list {
opacity: 1;
animation-name: none;
}
.select-box__current:focus + .select-box__list .select-box__option {
cursor: pointer;
}
.select-box__current:focus .select-box__icon {
transform: translateY(-50%) rotate(180deg);
}
.select-box__icon {
position: absolute;
top: 50%;
right: 15px;
transform: translateY(-50%);
width: 20px;
opacity: 0.3;
transition: 0.2s ease;
}
.select-box__value {
display: flex;
}
.select-box__input {
display: none;
}
.select-box__input:checked + .select-box__input-text {
display: block;
border-radius: 10px;
}
.select-box__input-text {
display: none;
width: 100%;
margin: 0;
padding: 15px;
background-color: #f8f8f8;
}
.select-box__list {
position: absolute;
width: 100%;
padding: 0;
list-style: none;
opacity: 0;
animation-name: HideList;
animation-duration: 0.5s;
animation-delay: 0.5s;
animation-fill-mode: forwards;
animation-timing-function: step-start;
box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}
.select-box__option {
display: block;
padding: 15px;
background-color: #fff;
}
.select-box__option:hover, .select-box__option:focus {
color: #546c84;
background-color: #fbfbfb;
}

@keyframes HideList {
from {
    transform: scaleY(1);
}
to {
    transform: scaleY(0);
}
}

.initiative-card{
    /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 10px;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
    cursor: pointer;
}
.initiative-card-title {
    font-weight: bold;
    font-size: 18px;
    color: #7b7b7b;
}
.initiative-provider{
    color: #08cad7;
    font-weight: bold;
}
.initiative-rate .rate-stars{
    margin: 0;
}
.initiative-provider{
    color: #08cad7;
    font-weight: bold;
}
.Price{
    color: #FA5456;
    font-size: 1.5rem;
    font-weight: bold;
}
.Date{
    border-top: 1px solid rgb(238, 238, 238);
    padding-top: 15px;
}
.Initiative_TrackingDetails__2RJEn{
    padding: 0 2rem 0 0;
}
.Initiative_ActionButtons__1BAwg{
    display: flex;  
    align-items: center; 
}
.Initiative_ActionButtons__1BAwg .Initiative_Price__2NF5J{
    margin-right: 2rem;
    font-weight: 500;
    color: #FA5456;
    font-size: 1.1rem;
}
.Initiative_SecondBottom__TQF62{
    margin-left: 1rem;
}
.Initiative_mainParagraph__3NAqi{
    color: #6e6e6e;
    font-size: 1.1rem;
}
.Initiative_TrackVideo__inCCz{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
}
.Initiative_TrackImgContainer__2EJTL,
.Initiative_CardImgContainer__2uAm1{
    overflow: hidden;
    height: auto;
    position: relative;
    border-radius: 10px;
}
.ForgetPassword_HeaderTitle__5MFFv{
    font-size: 30px;
    color: #101826;
    font-weight: 700;
}
.ForgetPassword_HeaderPargraph__2Hl_-{
    font-size: 16px;
    font-weight: 400;
    color: #868686;
}
.ForgetPassword_FormContainer__hXrGX{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
}

.ForgetPassword_LoginBackground__s5Xb3{
    width:100%
}

.ForgetPassword_FormPadding__Oy0QG{
    padding: 7rem 10rem;
}

.ForgetPassword_Input__1FMS7{
    height: 48px;
    padding: 14px 45px;
    margin: 0px 5px;
    border: 1px solid #192252 !important;
    border-radius: 8px !important;
    border: none;
    color: #848484;
    background: transparent;
}

.ForgetPassword_forgetPassword__3ErMg{
    text-align: right;
}
.ForgetPassword_CreateOne__2jBDx{
    margin-left: 5px;
    color: #FA5456 !important;
}

.ForgetPassword_error__y5qiz{
    color: red;
    margin-top: 10px;
}

@media (max-width: 699px) {

    .ForgetPassword_FormPadding__Oy0QG{
        padding: 1rem;
    }
}
.consultant-card {
 /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
  box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 10px;
  border-radius: 10px;
  margin-bottom: 35px;
  border: none;
  cursor: pointer;
  /* min-height: 500px; */
}
.consultant-card-title {
  font-weight: bold;
  font-size: 18px;
  color: #7b7b7b;
}
.consultant-provider {
  color: #08cad7;
  font-weight: bold;
}
.consultant-rate .rate-stars {
  margin: 0;
}

.ConsultantSessionCard_CardTitle__GDzup {
  background-color: #FA5456;
  border: 2px solid #FA5456;
  border-radius: 15px;
  padding: 10px 40px;
  color: #fff;
  cursor: pointer;
}

.ConsultantSessionCard_Item__BqlVf{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  margin-bottom: 25px;
  cursor: pointer;
  position: relative;
  margin: 0 1rem 2rem 0;
  padding: 1rem;
  background-color: #fff;
}
.ConsultantSessionCard_Owner__1vqwl{
  position: absolute;
  right: 0;
  top: 0;
  display: inline;
  width: auto;
  color: #fff;
  border-radius: 0px 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.ConsultantSessionCard_Level__1yP_n{
  color: #07b2bd;
  display: inline-block;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  font-size: 1.1rem;
  background: #f7c700;
}
.ConsultantSessionCard_Image__2kfhX{
  padding: 0 !important;
}
.ConsultantSessionCard_Image__2kfhX img{
  border-radius: 50%;
  max-width: 240px;
  border-radius: '10px';
}
.ConsultantSessionCard_Details__13rN7{
  padding: 30px 5px;
}

.ConsultantSessionCard_Details__13rN7 h4{
  font-weight: 500;
  font-size:18px;
  color: #07b2bd;
  margin: 15px 0 10px;
}

.ConsultantSessionCard_Details__13rN7 > p{
  color: #b3b3b3;
  font-weight: 500;
  font-size: .9rem;
}
.ConsultantSessionCard_Details__13rN7 .ConsultantSessionCard_Date__2scsJ{
  color: #08cad7;
  font-weight: bold;
}
.ConsultantSessionCard_Price__Qzd_z{
  color: #fff;
  background-color: #07b2bd;
  display: inline-block;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  font-size: 1.1rem;
}

@media only screen and (max-width: 699px) {
  .ConsultantSessionCard_Image__2kfhX img{
      max-width: 100% !important;
      width: 100% !important;
      height: 110px !important;
  }

  .ConsultantSessionCard_Details__13rN7{
      padding: 10px 5px;
  }
  .ConsultantSessionCard_Date__2scsJ > span{
      font-size: 12px;
  }
  .ConsultantSessionCard_Date__2scsJ > span > img{
      margin-right: 5px !important;
      width: 17px !important;
  }
  .ConsultantSessionCard_Price__Qzd_z,
  .ConsultantSessionCard_Level__1yP_n{
      font-size: 12px;
  }
}

.ConsultantSessionCard_ArrowDate__pK_wm{
  margin: 0 35px;
}

.ConsultantSessionCard_Provider__auHgm{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.ConsultantSessionCard_Start__24yMA p,
.ConsultantSessionCard_End__UB86I p{
  font-size: 18px;
}
.PageTitle{
    font-weight: bold;
    color: #6e6e6e;
    padding: 5rem 0 2rem;
}
.UserSessionTabs.nav-tabs{
    border: none;
}
.UserSessionTabs.nav-tabs .nav-link:focus,.UserSessionTabs .nav-tabs .nav-link:hover{
    border-color: #fff
}
.UserSessionTabs.nav-tabs .nav-link{
    padding-right: 3rem;
    padding-left: 3rem;
    font-weight: 600;
    color: #7b7b7b;
    font-size: 1.2rem;

}
.UserSessionTabs.nav-tabs .nav-link.active{
    color: #FA5456;
    border:none;
    border-bottom: 2px solid #FA5456;
    font-weight: 600;

}
.CircleProgress_Card__dfFgy {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
    margin: 10px 0
  }
  
  .CircleProgress_Card__dfFgy .CircleProgress_Percent__Xvw38 {
    position: relative;
  }
  
  .CircleProgress_Card__dfFgy svg {
    position: relative;
    width: 70px;
    height: 70px;
    transform: rotate(-90deg);
    scale: 1.5;
  }
  
  .CircleProgress_Card__dfFgy svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    
    stroke-width: 1;
    stroke-linecap: round;
  }
  
  .CircleProgress_Card__dfFgy svg circle:last-of-type {
    stroke-dasharray: 208px;
    stroke-dashoffset: calc(208px - (208px * var(--percent)) / 100);
    stroke: #FA5456; 
    stroke-width: 4px
  }
  
  .CircleProgress_Card__dfFgy .CircleProgress_Number__3RGKE {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .CircleProgress_Card__dfFgy .CircleProgress_Number__3RGKE h3 {
    font-size: 28px;
    margin: 0;
  }
  
  .CircleProgress_Card__dfFgy .CircleProgress_Number__3RGKE h3 span {
    font-size: 28px;
  }


  @media (max-width: 768px) {
    .CircleProgress_Card__dfFgy svg{
      scale: 1;
    }
    .CircleProgress_Card__dfFgy .CircleProgress_Number__3RGKE h3 {
      font-size: 20px;
    }
    .CircleProgress_Card__dfFgy .CircleProgress_Number__3RGKE h3 span{
      font-size: 20px;
    }
  }
  
.TrackingCard_Item__1d1bl{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
    position: relative;
    margin: 0 1rem 2rem;
    padding: 1rem;
}
.TrackingCard_Owner__1RDnR{
    position: absolute;
    right: 0;
    top: 0;
    display: inline;
    width: auto;
    color: #fff;
    border-radius: 0px 10px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #07b2bd;
}
.TrackingCard_Level__Z3wMY{
    color: #07b2bd;
    display: inline-block;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    font-size: 1.1rem;
    background: #e4fdff;
}
.TrackingCard_Image__3SMT6{
    padding: 0 !important;
}
.TrackingCard_Image__3SMT6 img{
    border-radius: 10px;
    max-width: 240px;
    border-radius: '10px';
}
.TrackingCard_Details__26wkc{
    padding: 30px 5px;
}

.TrackingCard_Details__26wkc h4{
    font-weight: 500;
    font-size:18px;
    color: #07b2bd;
    margin: 15px 0 10px;
}

.TrackingCard_Details__26wkc > p{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}
.TrackingCard_Details__26wkc .TrackingCard_Date__3tE8K{
    color: #08cad7;
    font-weight: bold;
}
.TrackingCard_Price__3EUew{
    color: #fff;
    background-color: #07b2bd;
    display: inline-block;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    font-size: 1.1rem;
}

@media only screen and (max-width: 699px) {
    .TrackingCard_Image__3SMT6 img{
        max-width: 100% !important;
        width: 100% !important;
        height: 110px !important;
    }

    .TrackingCard_Details__26wkc{
        padding: 10px 5px;
    }
    .TrackingCard_Date__3tE8K > span{
        font-size: 12px;
    }
    .TrackingCard_Date__3tE8K > span > img{
        margin-right: 5px !important;
        width: 17px !important;
    }
    .TrackingCard_Price__3EUew,
    .TrackingCard_Level__Z3wMY{
        font-size: 12px;
    }
}
.RequestConsultation_HeaderTitle__267L5 {
  color: #08cad7;
  font-weight: 600;
}
.RequestConsultation_TopImg__3OD9c {
  width: 300px;
}
.RequestConsultation_HeaderPargraph__3dTzh {
  color: #b3b3b3;
  font-weight: 600;
}
.RequestConsultation_FormContainer__fD5A3 {
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  background: #f9f9f9;
}

.RequestConsultation_LoginBackground__xz69F {
  width: 100%;
}

.RequestConsultation_FormPadding__18i7- {
  padding: 2rem 10rem;
}

.RequestConsultation_FormInput__2e5hG,
.RequestConsultation_TextArea__j25_Y {
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: #fff;
  border: none;
  color: #848484;
}
.RequestConsultation_FormInput__2e5hG {
  height: 48px;
  border-radius: 15px;
  background-color: #fff;
}

.RequestConsultation_forgetPassword__2Zv-A {
  text-align: right;
}
.RequestConsultation_CreateOne__3c3Vy {
  margin-left: 5px;
  color: #FA5456 !important;
}

.RequestConsultation_CheckBoxesContainer__3xFeF [type='radio']:checked,
.RequestConsultation_CheckBoxesContainer__3xFeF [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.RequestConsultation_CheckBoxesContainer__3xFeF [type='radio']:checked + label,
.RequestConsultation_CheckBoxesContainer__3xFeF [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.RequestConsultation_CheckBoxesContainer__3xFeF [type='radio']:checked + label:before,
.RequestConsultation_CheckBoxesContainer__3xFeF [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.RequestConsultation_CheckBoxesContainer__3xFeF [type='radio']:checked + label:after,
.RequestConsultation_CheckBoxesContainer__3xFeF [type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #FA5456;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.RequestConsultation_CheckBoxesContainer__3xFeF [type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.RequestConsultation_CheckBoxesContainer__3xFeF [type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.RequestConsultation_CheckBox__1RgDP {
  display: inline-block;
  margin-right: 15px;
}

@media (max-width: 768px) {
  .RequestConsultation_FormPadding__18i7- {
    padding: 2rem;
  }
  .RequestConsultation_TopImg__3OD9c {
    width: 200px;
  }
}

.RequestConsultation_Drop__2SKfY {
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: white;
  border: none;
  color: #848484;
}
.RequestConsultation_Drop__2SKfY:hover,
.RequestConsultation_Drop__2SKfY:active,
.RequestConsultation_btn__2yf4L:first-child:active,
.RequestConsultation_Drop__2SKfY.RequestConsultation_btn__2yf4L.RequestConsultation_show__3Kn1Q {
  background-color: white !important;
  color: #848484 !important;
}

.ShowUserProfile_HeaderTitle__gjptl {
  color: #08cad7;
  font-weight: 600;
}
.ShowUserProfile_HeaderPargraph__15PXQ {
  color: #b3b3b3;
  font-weight: 600;
}
.ShowUserProfile_FormContainer__2zQBN {
  
  max-width: 100%;
  overflow: hidden;
}

.ShowUserProfile_LoginBackground__3FENe {
  width: 100%;
}

.ShowUserProfile_FormPadding__3Nfmu {
  padding: 4rem 10rem;
}

.ShowUserProfile_FormInput__d7a6s,
.ShowUserProfile_TextArea__2smmB {
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  color: #848484;
  border: 1px solid #D3D3D3;
}
.ShowUserProfile_FormInput__d7a6s {
  height: 48px;
}

.ShowUserProfile_forgetPassword__Vb6PZ {
  text-align: right;
}
.ShowUserProfile_CreateOne__1oPo_ {
  margin-left: 5px;
  color: #FA5456 !important;
}

.ShowUserProfile_CheckBoxesContainer___qXVZ [type='radio']:checked,
.ShowUserProfile_CheckBoxesContainer___qXVZ [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.ShowUserProfile_CheckBoxesContainer___qXVZ [type='radio']:checked + label,
.ShowUserProfile_CheckBoxesContainer___qXVZ [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.ShowUserProfile_CheckBoxesContainer___qXVZ [type='radio']:checked + label:before,
.ShowUserProfile_CheckBoxesContainer___qXVZ [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.ShowUserProfile_CheckBoxesContainer___qXVZ [type='radio']:checked + label:after,
.ShowUserProfile_CheckBoxesContainer___qXVZ [type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #FA5456;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.ShowUserProfile_CheckBoxesContainer___qXVZ [type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.ShowUserProfile_CheckBoxesContainer___qXVZ [type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.ShowUserProfile_CheckBox__Xgf4K {
  background-color: #fff;
  display: flex;
  align-items: center;
}

.ShowUserProfile_Drop__3IQx1 {
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: transparent;
  border: none;
  color: #848484;
  border: 1px solid #D3D3D3;
}
.ShowUserProfile_Drop__3IQx1:hover,
.ShowUserProfile_Drop__3IQx1:active,
.ShowUserProfile_btn__BD2AG:first-child:active,
.ShowUserProfile_Drop__3IQx1.ShowUserProfile_btn__BD2AG.ShowUserProfile_show__1ujs9 {
  background-color: #f5f5f5 !important;
  color: #848484 !important;
}

.ShowUserProfile_RefCode__JUWDp{
  cursor: pointer;
  padding: 20px;
  border-radius: 5px;
  margin: 5px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  height: 70px;
  font-size: 18px;
}

.ShowUserProfile_RefCode__JUWDp:first-of-type{
  border: 1px solid #0576e3;
}

.ShowUserProfile_RefCode__JUWDp:last-of-type{
  border: 1px solid #e2621b;
}
.SessionCard_Item__3iM0X{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
    position: relative;
    margin: 0 1rem 2rem 0;
    padding: 1rem;
}
.SessionCard_Owner__1kxfT{
    position: absolute;
    right: 0;
    top: 0;
    display: inline;
    width: auto;
    color: #fff;
    border-radius: 0px 10px;
    font-size: 1.2rem;
    font-weight: bold;
}
.SessionCard_Level__3hjbQ{
    color: #07b2bd;
    display: inline-block;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    font-size: 1.1rem;
    background: #f7c700;
}
.SessionCard_Image__11KPp{
    padding: 0 !important;
}
.SessionCard_Image__11KPp img{
    border-radius: 50%;
    max-width: 240px;
    border-radius: '10px';
}
.SessionCard_Details__1PEfU{
    padding: 30px 5px;
}

.SessionCard_Details__1PEfU h4{
    font-weight: 500;
    font-size:18px;
    color: #07b2bd;
    margin: 15px 0 10px;
}

.SessionCard_Details__1PEfU > p{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}
.SessionCard_Details__1PEfU .SessionCard_Date__2GTJN{
    color: #08cad7;
    font-weight: bold;
}
.SessionCard_Price__KWAgs{
    color: #fff;
    background-color: #07b2bd;
    display: inline-block;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    font-size: 1.1rem;
}

@media only screen and (max-width: 699px) {
    .SessionCard_Image__11KPp img{
        max-width: 100% !important;
        width: 100% !important;
        height: 110px !important;
    }

    .SessionCard_Details__1PEfU{
        padding: 10px 5px;
    }
    .SessionCard_Date__2GTJN > span{
        font-size: 12px;
    }
    .SessionCard_Date__2GTJN > span > img{
        margin-right: 5px !important;
        width: 17px !important;
    }
    .SessionCard_Price__KWAgs,
    .SessionCard_Level__3hjbQ{
        font-size: 12px;
    }
}

.SessionCard_ArrowDate__2OHVp{
    margin: 0 35px;
}

.SessionCard_Provider__gNs3C{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.SessionCard_Start__1mRux p,
.SessionCard_End__39GUr p{
    font-size: 18px;
}
.shareDropdown .dropdown-toggle::after{
  display: none;
}
.shareDropdown .dropdown-menu.show{
  box-shadow: 0 8px 24px 0 rgba(183, 183, 183, 0.17)
}
.shareDropdown .btn.show{
  border: none;
  
}
.shareDropdown .dropdown-item{
  display: flex;
  align-items: center;

}
.shareDropdown .dropdown-item .social-name{
  margin: 0 10px;
}
.SecondaryBlueButton_CardButton__3FSNS{
    text-align: center;
    width: 100%;
    outline: none;
    border: 1px solid var(--blue) !important;
    background: #fff !important;
    color: var(--blue) !important;
    border-radius: 8px;
    padding: 15px 50px;
    cursor: pointer;
    font-weight: 700;
}
.SecondaryBlueButton_CardButton__3FSNS:hover,
.SecondaryBlueButton_CardButton__3FSNS:active{
    background: #fff !important;
}
.ContactUs_HeaderTitle__10tA4{
    color: #24A1AB;
    font-weight: 700;
    font-size: 54px;
}
.ContactUs_HeaderTitle__10tA4 span{
    color:#FF5B5B
}
.ContactUs_FormContainer__rXlWa{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
}

.ContactUs_Background__V7gMA{
    width:100%
}

.ContactUs_FormPadding__3HW7E {
    padding: 1rem 2rem;
  }
  @media (max-width: 768px) {
    .ContactUs_FormPadding__3HW7E {
      padding: 2rem;
    }
  }

.ContactUs_TextArea__1RPJp{
    padding: 14px 25px;
    margin: 0px 5px;
    border-radius: 10px;
    border: 1px solid #192252;
    color: #181C32;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
}
.ContactUs_ValidationMessage__1zp9U{
    font-size: 0.8rem;
    text-align: right;
}
.ContactUs_Contacts__3rGku{
    margin-top: 4rem;
}
.ContactUs_Contacts__3rGku h6{
    margin-top: 30px;
    font-weight: 600;
}
.ContactUs_Contacts__3rGku img{
    width: 45px;
    margin: 10px;
}

.TermsAndConditions_TermsDetails__3_SYj{
    padding: 0 2rem 0 0;
}
.TermsAndConditions_mainParagraph__14-IZ{
    color: #6e6e6e;
    font-size: 1.3rem;
    font-weight: 600;
}

.course-item{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 9px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
}
.course-image{
    padding: 0 !important;
}
.course-image img{
    border-radius: 10px;
    width: 220px;
     height: 200px;
    }
.course-details{
    padding: 30px 40px !important;
}

.course-details h4{
    font-weight: bold;
    font-size:18px;
    color: #7b7b7b;
}

.course-details > p{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}
.course-details .course-provider{
    color: #08cad7;
    font-weight: bold;
}



.rate-number{
    color: #7b7b7b;
    font-weight: 600;
    font-size: 1.15rem;
    margin-left: 10px;
}

.course-views{
    color: #b3b3b3;
    font-weight: 500;
    font-size: 1.2rem;
}
.course-views-icon{
    margin-right: 5px;
}
.course-price{
    font-size: 1.3rem;
    font-weight: bold;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.course-price .discount-price{
    color:#FA5456;
}

.course-price .real-price{
    text-decoration: line-through;
    color: #b3b3b3;
    text-decoration-thickness: 2px;
}
.ShoppingCart_ShoppingCartTitle__2ZBpv{
    margin-bottom: 2rem;
}

.ShoppingCart_CartItems__3msZ-{
    color: #6e6e6e;
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 2rem;
    
}
.ShoppingCart_ShoppingCartContainer__2A84J{
    padding: 0 5rem;
}
.ShoppingCart_CheckoutDetails__1iSaK h6{
    color: #6a6f73;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 17px;
}
.ShoppingCart_CheckoutDetails__1iSaK > div{
    margin-bottom: 1rem;
}
.ShoppingCart_CheckoutDetails__1iSaK span{
    font-weight: 700;
    font-size: 17px;
    color: #7f7f7f;
}

@media (max-width: 699px) {
    .ShoppingCart_ShoppingCartContainer__2A84J{
        padding: 0 1.5rem;
    }
    .ShoppingCart_CartDetails__19HZN{
        padding: 0;
    }
    .ShoppingCart_CheckoutDetails__1iSaK > div{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .ShoppingCart_CheckoutDetails__1iSaK span{
        font-size:1.5rem
    }
    .ShoppingCart_CartDetails__19HZN h4{
        margin-top: 25px;
    }
  }

.footer{   
    color: #fff;
    padding: 2rem 2rem 0rem 2rem;
    background: #01A5B1;
}
@media only screen and (max-width: 699px) {
    .footer{
        padding: 4rem 2rem;
    }
}
.FooterLogo img{
    /*filter: brightness(0) invert(1);*/
    width: 58px;
    margin-bottom: 1rem;
}

.FooterTitle{
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid #fff;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.Sitemap > p{
    margin-bottom: 10px;
}

.Sitemap  > a{
    color: #fff;
}
.SiteLink a{
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}

.ContactImg{
    width: 35px;
    height: auto;
    margin-right: 10px;
    border-radius: 50%;
}
.CopyRight{
    
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.SocialIcons{
    font-size: 50px;
}
@media (max-width: 768px) {
    .SocialIcons {
      text-align: left;
    }
    .SocialIcons svg{
        margin-left: 0;
    }
  }
.SocialIcon {
    
    padding: 5px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}
.aboutNurabi{
    font-size: 13px;
}
#basic-navbar-nav{
    background: rgb(255, 255, 255);
}
#basic-nav-dropdown{
    font-weight: 500;
    font-size: 20px;
    text-decoration: none;
    padding: 0 10px;
    color: #07b2bd;
}
.dropdown-menu.show a{
    color: #959393;
    padding-left: 15px;
}
@media (min-width: 1400px){
    .NavContainer{
    max-width: 1400px;
    }
}
@media (min-width: 699px){
    .DashboardDropDown{
        display: none;
    }
}
.lang{
    color: #08cad7 !important;
}
.login-register{
    padding: 15px 40px !important;
    background: var(--blue);
    color: #fff !important;
    border-color: var(--blue);
    font-size: 15px !important;
    border-radius: 40px;
   
}
.login-register-2{
    padding: 15px 40px !important;
    background: #008993;
    color: #fff !important;
    border-color: #008993;
    font-size: 15px !important;
    border-radius: 40px;
   
}
.navbar-nav{
    display: initial;
}
.join-link{
    background: #FF9640 !important;
    border: 2px solid #FF9640 !important;
    padding: 10px 25px !important;
    border-radius: 15px;
    color: #fff !important;
    cursor: pointer;
    
}
.NavigationHeaderV2_active__1-NnI{
    color:#FA5456;
    font-weight: 600;
    font-size: 17px;
    
}
.NavigationHeaderV2_MainNavHeaderLink__10Q7V{
    font-weight: 600;
    color: #000;
    padding: 20px 5px;
    font-size: 16px;
    text-decoration: none;
    display: inline-block;
}
.NavigationHeaderV2_NavBar__rPGuu{
    background: #fff;
    height: 100%;
}
.NavigationHeaderV2_Logo__1IIT3{
    width: 80px;
    overflow: hidden;
}
.NavigationHeaderV2_Logo__1IIT3 img{
    width: 100%;
}
.NavigationHeaderV2_Switcher__2nmIz{
    background: #08cad7;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    padding: 5px;
}

@media (max-width: 699px) {
    .NavigationHeaderV2_MainNavHeaderLink__10Q7V{
        padding: 10px 5px;
        font-size: 17px;
        display: block;
    }
    .NavigationHeaderV2_NoramlNavItem__K4I3s{
        display: inline-block;
    }
    .NavigationHeaderV2_active__1-NnI{
        padding: 10px 5px;
    }
  }
.custom-tabs.nav-tabs{
    border-bottom: none;
    justify-content: center;
    margin-top: 1rem;
    
}

.custom-tabs.nav-tabs .nav-item > button{
    border: 2px solid rgba(2,3,52,.102);
    margin: 0 10px;
    color: #020334;
    padding: 4px 20px 6px;
    border-radius: 15px;
    font-weight: 600;
    transition: all .5s ease 0s;
    background: 0 0;
    font-size: 20px;
    outline: none;
}
.custom-tabs.nav-tabs .nav-item > button.active{
    background: #FA5456;
    color: #fff;
    border-color: #FA5456;
}
.carouselItem{
    padding: 0 20px;
}
.join-consultants{
    background: #e9fcf8;
    padding: 4rem 2rem;
    border-radius: 20px;
}
.custom-tab-button{
    border: 2px solid rgba(2,3,52,.102);
    margin: 0 10px;
    color: #020334;
    padding: 4px 20px 6px;
    border-radius: 5px;
    font-weight: 600;
    transition: all .5s ease 0s;
    background: 0 0;
    font-size: 20px;
    outline: none;
}

.custom-tab-button.active{
    background: #FA5456;
    color: #fff;
    border-color: #FA5456;
}

  .divider {
    width: 50px;
    height: 3px;
    background-color: #f2994a;
    margin: 10px auto;
  }
  
  .step-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .step-container h5{
    font-size: 16px;
    font-weight: 600;
  }
  .step-container p{
    color: #807F80;
    font-size: 14px;
    line-height: normal;
    width: 150px;
    margin-top: 5px;
  }

  .circle {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    border: 1px solid;
  }
  
  .pink {
    color: #ED61A4;
    border-color: #ED61A4;
  }
  
  .blue {
    color: #1BCDDB;
    border-color: #1BCDDB;
  }

  .orange {
    color: #FF9640;
    border-color: #FF9640;
  }
  
  .arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .section-header{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .section-desc{
    font-size: 16px;
    font-weight: 400;
  }

.story-card{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
    cursor: pointer;
}
.story-card-title {
    font-weight: bold;
    font-size: 18px;
    color: #7b7b7b;
}

.story-user{
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    left: 50%;
    bottom: -2rem;
    transform: translate(-50%);
}
.initiative-card{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
    cursor: pointer;
}
.initiative-card-title {
    font-weight: bold;
    font-size: 18px;
    color: #7b7b7b;
}
.initiative-provider{
    color: #08cad7;
    font-weight: bold;
}
.initiative-rate .rate-stars{
    margin: 0;
}
.initiative-provider{
    color: #08cad7;
    font-weight: bold;
}
.Price{
    color: #FA5456;
    font-size: 1.1rem;
    font-weight: bold;
}
.Date{
    border-top: 1px solid rgb(238, 238, 238);
    padding-top: 15px;
}
.initiative-card{
    /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 10px;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
    cursor: pointer;
}
.initiative-card-title {
    font-weight: bold;
    font-size: 18px;
    color: #7b7b7b;
}
.initiative-provider{
    color: #08cad7;
    font-weight: bold;
}
.initiative-rate .rate-stars{
    margin: 0;
}
.initiative-provider{
    color: #08cad7;
    font-weight: bold;
}
.Price{
    color: #FA5456;
    font-size: 1.1rem;
    font-weight: bold;
}
.Date{
    border-top: 1px solid rgb(238, 238, 238);
    padding-top: 15px;
}
.Quiz_personalityTest__2LxSv{
  padding-top: 5rem;
  margin-top: 100px;
  margin-bottom: 100px;
}

.Quiz_Questions__17mx4 [type="radio"]:checked,
.Quiz_Questions__17mx4 [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.Quiz_Questions__17mx4 [type="radio"]:checked + label,
.Quiz_Questions__17mx4 [type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.Quiz_Questions__17mx4 [type="radio"]:checked + label:before,
.Quiz_Questions__17mx4 [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.Quiz_Questions__17mx4 [type="radio"]:checked + label:after,
.Quiz_Questions__17mx4 [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #FA5456;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.Quiz_Questions__17mx4 [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.Quiz_Questions__17mx4 [type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.Quiz_Question__2W4xz{
  background-color: #fff;
  display: flex;
  align-items: center;
  border: 2.5px solid #eee;
  padding: 1rem 2rem;
  border-radius: 15px;
  margin-bottom: 20px;
}
.Quiz_QuestionFooter__kf0X4{
  border: solid 1px #ddd;
  background-color: #f8f8f8;
  padding: 40px;
}

.Quiz_NextButton__m4LvJ{
  text-align: center;
  width: 100%;
  background-color: #FA5456;
  outline: none;
  border: 2px solid #FA5456;
  border-radius: 15px;
  padding: 10px 35px;
  font-weight: 600;
  font-size: 1.2rem;
  
}
.Quiz_NextButton__m4LvJ:hover,
.Quiz_NextButton__m4LvJ:active{
  background-color: #FA5456 !important; 
  border: 2px solid #FA5456;
}

.Quiz_NextButton__m4LvJ:disabled{
  background-color: #919191 !important;
  border: 2px solid #919191;
  color: #fff;
  font-size: 1.2rem;
}

.Quiz_PersonalityTestDesc__3rB36{
  max-width: 500px;
  margin: auto;
}
.Quiz_QuestionNumber__1-4jq{
  font-weight: bold;
}
.PracticalTaskUplaod_UploadBtnWrapper__3jB8H{
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.PracticalTaskUplaod_UploadBtnWrapper__3jB8H button {
    border: 3px dashed #FA5456;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 20px;
    font-weight: bold;
    
  }
  
  .PracticalTaskUplaod_UploadBtnWrapper__3jB8H input{
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .PracticalTaskUplaod_FilePreview__LnYDU{
    border: 2px solid #FA5456;
    padding: .7rem 1rem;
    border-radius: 15px;
    margin: 2rem 0;
  }
  .PracticalTaskUplaod_FileName__1mRc4{
    color: #FA5456;
    font-weight: 600;
    font-size: 18px;
  }

.PracticalTaskUplaod_SubmitButton__1mSZj{
    text-align: center;
    width: 100%;
    background-color: #FA5456;
    outline: none;
    border: 2px solid #FA5456;
    border-radius: 15px;
    padding: 10px 35px;
    font-weight: 600;
    font-size: 1.2rem;
    
  }
  .PracticalTaskUplaod_SubmitButton__1mSZj:hover,
  .PracticalTaskUplaod_SubmitButton__1mSZj:active{
    background-color: #FA5456 !important; 
    border: 2px solid #FA5456;
  }
  
  .PracticalTaskUplaod_SubmitButton__1mSZj:disabled{
    background-color: #919191 !important;
    border: 2px solid #919191;
    color: #fff;
    font-size: 1.2rem;
  }

  .PracticalTaskUplaod_LoaderDiv__1oJ2b{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background: rgba(0,0,0, 0.1);   
    z-index: 5;
  } 
 
.header{
    height: 350px;
    background-color: var(--babyBlue);
    margin-bottom: 3rem;
}

.breadcrumb{
    flex-direction: column;
}

.breadcrumb-header{
    color:#1C0A26;
    font-weight: 600;
}

.breadcrumb-details span{
    color: #a1a1a1;
    font-weight: 600;
}

.breadcrumb-details span.active-page {
    color:#08cad7;
}

.rate-number{
    color: #7b7b7b;
    font-weight: 600;
    font-size: 1.15rem;
}

.course-title,
.course-header h5{
    font-weight: bold;
    color: #6e6e6e;
    
}
.course-header h5{
    margin-bottom: 2px;
}
.course-header p{
    color: #b3b3b3;
    font-weight: 500;
}
.course-description{
    color: #b3b3b3;
    font-weight: 500;
}
.course-provider{
    color: #08cad7;
    font-weight: bold;
    margin-bottom: 15px;
}


.section-container .collapse{
    padding: 10px 20px 10px 0;
}

.section-item-collapse{
    padding: 10px 20px;
    background-color: #f8f8f8;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
}

.section-title{
    margin-top: 0;
    margin-bottom: 0;
}

.section-item {
    align-items: flex-start;
    margin: 20px 0;
}

.section-item p{
    margin-bottom: 0;
}
.icon-type{
    padding: 10px 15px 15px;
    margin-right: 10px;
    border-radius: 7px;
    background-color: #fee;
    color: #FA5456;
}
.lock-icon{
    border-radius: 7px;
    color: #FA5456;
    font-size: 1.2rem;
}

.lecture-type{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}

.lecture-title a{
    color: #000;
}
.lecture-type a{
    color: #828282;
}
.course-title,
.course-header h5{
    font-weight: bold;
    color: #6e6e6e;
    
}
.course-header h5{
    margin-bottom: 2px;
}
.course-header p{
    color: #b3b3b3;
    font-weight: 500;
}
.course-description{
    color: #4E4E4E;
    font-weight: 400;
    font-size: 13px;
}
.course-provider{
    color: #6e6e6e;
    font-weight: normal;
    margin-bottom: 15px;
}

.course-content-sections{
    padding-right: 0px;
}

.section-container {
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
}

.section-container .collapse{
    padding: 10px 20px 10px 0;
}

.section-item-collapse{
    padding: 10px 20px;
    background-color: var(--babyBlue);
    border-radius: 0;
    cursor: pointer;
    margin: 0px;
    box-shadow: none;
}

.section-title{
    margin-top: 0;
    margin-bottom: 0;
}

.section-item {
    align-items: center;
    margin: 25px 0 30px;
    cursor: pointer;
    padding: 0 1rem;
}

.section-item p{
    margin-bottom: 0;
}
.icons-container img{
    margin-right: 10px;
    
}
.lock-icon{
    border-radius: 7px;
    color: #FA5456;
    font-size: 1.2rem;
}

.lecture-type{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}

.live-date span{
    color: #b3b3b3;
}

@media (max-width: 768px) {
    .course-content-sections{
        padding-right: 0px;
    }
}
.comment-item{
    
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 5px 15px;
}
.comment-image{
    padding: 0 !important;
}
.comment-image img {
    
    max-width: 52px;
    width: 52px;
    height: 52px;
    
  }
.comment-details{
    padding: 0 !important;
}

.comment-details h4{
    font-weight: bold;
    font-size:18px;
    color: #7b7b7b;
    margin: 5px 0;
}

.comment-details > p{
   
    color: #2A2A2A;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.28px;
}
.comment-details .comment-provider{
    color: #08cad7;
    font-weight: bold;
}






.comment-price{
    font-size: 1.3rem;
    font-weight: bold;
    text-align: right;
}

.comment-price .discount-price{
    color:#FA5456
}

.comment-price .real-price{
    text-decoration: line-through;
    color: #b3b3b3;
    text-decoration-thickness: 2px;
}
.course-details-card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
}

.card-img-container{
    overflow: hidden;
    height: 240px;
    position: relative;
    border-radius: 10px;
}
.card-img-container img{
    width: 100%;
    height: auto;
}
.course-body{
    padding: 20px 35px;
}
.course-body h5{
    font-weight: bold;
    margin-bottom: 0;
    font-size: 1.1rem;
    color: #7b7b7b;
}
.course-features{
    margin-top: 5px;
}
.course-features p {
    margin: 0 0 5px 0;
}
.course-features p span {
    margin-right: 5px;
}
.discount-price{
    font-size: 1.3rem;
    color: #FA5456;
    font-weight: bold;
}

.real-price {
    text-decoration: line-through;
    text-decoration-thickness: auto;
    color: #b3b3b3;
    text-decoration-thickness: 2px;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
}
.discount-percentage{
    color: #08cad7;
    font-weight: bold;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.2);
}
.preview-icon{
    color: white;
    font-size: 2rem;
}

.icon-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
}

@media (max-width: 768px) {
    .course-details-card{
        margin-top: 0;
        position: inherit;
        width: 100%;
    }
    
}


.SignUpEmail_HeaderTitle__2KceJ{
    margin: 2rem 0;
    text-align: center;
    border-bottom: 1px solid #e3e3e3;
    line-height: .1em;
}

.SignUpEmail_HeaderTitle__2KceJ span{
    background: var(--babyBlue);
    padding: 0 10px;
  }
.SignUpEmail_HeaderPargraph__1L26n{
    color: #b3b3b3;
    font-weight: 600;
}
.SignUpEmail_FormContainer__1vnZs{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    background: var(--babyBlue);
}

.SignUpEmail_FormPadding__2fbcj{
    padding: 5rem 10rem;
}

.SignUpEmail_Input__kgYLD{
    height: 48px;
    padding: 14px 25px;
    margin: 0px 5px;
    border-radius: 15px;
    background-color: #fff;
    border: none;
    color: #848484;
}

.SignUpEmail_SignUpBackground__A2pn9{
    width:100%
}

.SignUpEmail_error__1iAXC{
    color: red;
    margin-top: 10px;
}
@media (max-width: 699px) {

    .SignUpEmail_FormPadding__2fbcj{
        padding: 1rem;
    }
}


.SignUpEmail_EmailInput__2Msfd {
  height: 48px;
  padding: 14px 45px;
  margin: 0px 5px;
  border: 1px solid #192252 !important;
  border-radius: 8px !important;
  border: none;
  color: #848484;
  background: transparent;
}

.SignUpEmail_SignTitle__NtVqe{
    font-size: 30px;
    color: #101826;
    font-weight: 700;
  }
  .SignUpEmail_GrayTitle__2eP5o{
    font-size: 16px;
    font-weight: 400;
    color: #868686;
  }

  .SignUpEmail_GoogleSignIn__3K7fG,
.SignUpEmail_LoginOption__1pcf6 {
    padding: 14px 25px;
    border-radius: 8px;
    color: #3E3E3E;
    width: 100%;
    display: flex;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    margin-top: 5px;
    justify-content: center;
    font-size: 17px;
    border: 1px solid #192252 !important;
    margin-top: 20px;
}

.SignUpEmail_GoogleSignIn__3K7fG img,
.SignUpEmail_LoginOption__1pcf6 img {
  margin: 0 10px;
}
.card-button{
    text-align: center;
    width: 100%;
    background-color: var(--blue);
    outline: none;
    border: 2px solid var(--blue);
    border-radius: 8px !important;
    padding: 15px 40px;
    font-weight: 600;
}
.card-button:hover,
.card-button:active{
    background-color: var(--blue) !important; 
    border: 2px solid var(--blue);
}
.ResetPassword_PasswordInput__2dJuz{
    height: 48px;
    padding: 14px 25px;
    margin: 0px 5px;
    border-radius: 15px;
    border: none;

    background-color: #f5f5f5;
    border: none;
    color: #08cad7;
    font-weight: 600;
}

.ResetPassword_togglePassword___LrGL{
    position: absolute;
    display: inline-block;
    right: 15px;
    top: 10px;
}

.ResetPassword_HeaderTitle__3Aj_2{
    color: #08cad7;
    font-weight: 600;
}
.ResetPassword_HeaderPargraph__2k1sN{
    color: #b3b3b3;
    font-weight: 600;
}
.ResetPassword_FormContainer__3wceQ{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
}

.ResetPassword_LoginBackground__3rsk2{
    width:100%
}

.ResetPassword_FormPadding__nODkS{
    padding: 10rem;
}

.ResetPassword_Input__3zGBT{
    height: 48px;
    padding: 14px 25px;
    margin: 0px 5px;
    border-radius: 10px;
    background-color: #f5f5f5;
    border: none;
    color: #848484;.ResetPassword_HeaderTitle__3Aj_2{
    color: #08cad7;
    font-weight: 600;
}
.ResetPassword_HeaderPargraph__2k1sN{
    color: #b3b3b3;
    font-weight: 600;
}
.ResetPassword_FormContainer__3wceQ{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
}

.ResetPassword_LoginBackground__3rsk2{
    width:100%
}

.ResetPassword_FormPadding__nODkS{
    padding: 10rem;
}

.ResetPassword_Input__3zGBT{
    height: 48px;
    padding: 14px 25px;
    margin: 0px 5px;
    border-radius: 10px;
    background-color: #f5f5f5;
    border: none;
    color: #848484;
}

.ResetPassword_forgetPassword__2Tx39{
    text-align: right;
}
.ResetPassword_CreateOne__3Zjsu{
    margin-left: 5px;
    color: #FA5456 !important;
}

.ResetPassword_error__2B_Pz{
    color: red;
    margin-top: 10px;
}
}

.ResetPassword_forgetPassword__2Tx39{
    text-align: right;
}
.ResetPassword_CreateOne__3Zjsu{
    margin-left: 5px;
    color: #FA5456 !important;
}

.ResetPassword_error__2B_Pz{
    color: red;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .ResetPassword_FormPadding__nODkS{padding: 2rem;}
}
.side-menu-bar nav{
	position:absolute;
	top:0;
	left:0;
	display:flex;
	flex-direction:column;
	width:21%;
	min-height: 100vh;
	background-color: #fff;
	padding:2rem 1rem 1rem 1rem;
	transition: transform 1s;
    z-index: 3;
}

.side-menu-bar .navbar{
	transform: translateX(-100%);
}

.side-menu-bar .nav-btn{
	position: absolute;
    transform: translateX(38px);
    top: 45px;
    right: 0px;
    width: 40px;
    height: 40px;
    
    border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0acad7;
    outline: none;
    border: none;
    font-size: 1rem;
    padding: 5px 10px;
    cursor: pointer;
    color: #fff;
}

.side-menu-bar .logo{
	display: block;
	width: 100px;
	margin: auto;
	background:transparent;
}

.side-menu-bar .logo img{
	display: block;
	width: 60px;
	height: 100%;
}

.side-menu-bar .nav-link{
	display: flex;
	align-items: center;
	color: #000;
	text-decoration: none;
	padding: 5px 0rem;
	border-radius: 5px;
	margin-bottom: 5px;
}

.side-menu-bar .nav-link:hover{
	background-color:#AFB4FF;
	color: #4e4e4e;
}

.side-menu-bar .nav-link span{
	margin-left: 10px;
}

.side-menu-bar .nav-top{
	margin-top: 2rem;
}

.side-menu-bar .nav-top .main-head p:first-child{
	font-size: 23px;
}

.side-menu-bar .nav-top .main-head p:last-child{
	color: #b8b9bb;
	font-size: 15px;
}

.side-menu-bar .mobile-nav{
	background-color:#753ffd;
	width: 100%;
	height:40px;
	display: none;
}

.side-menu-bar .mobile-nav-btn{
	color: #fff;
	background: transparent;
	outline: none;
	border: none;
	margin: 0 10px;
}

.side-menu-bar .store-icons img{
	border-radius: 15px;
}

@media only screen and (max-width: 768px){
	.side-menu-bar nav{
		width: 40%;
		padding: 10px;
	}
}

@media only screen and (max-width: 450px){
	.side-menu-bar .mobile-nav{
		display: flex;
		align-items: center;
	}
	.side-menu-bar nav{
		width: 70%;
		top: 0px;
		min-height: calc(100vh - 38px);
	}

	.side-menu-bar .nav-btn{
		display: none;
	}
}

.navbar{
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.lessonTitle h4,
.languages h4 {
  font-weight: bold;
  color: #6e6e6e;
}
.leave-feedback {
  margin-right: 10px;
}
.leave-feedback button {
  padding: 0px 20px;
}
.slotContainer {
  border: 2px solid #FA5456;
  padding: 10px 20px;
  border-radius: 15px;
  position: relative;
  background-color: yellow;
  cursor: pointer;
}

.rightBtn {
  color: rgb(251, 84, 86);
  background: #e9fcf8;
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 15px 15px 0px;
  padding: 0 10px;
}

.Checkout_CheckoutDetails__3IDYQ{
    padding: 0 2rem 0 0;
}
.Checkout_ChackoutContainer__1BE3t{
    padding: 0 5rem;
}
.Checkout_CheckoutDetails__3IDYQ h6{
    color: #6a6f73;
    font-weight: 700;
    margin-bottom: 0;
}
.Checkout_CheckoutDetails__3IDYQ > div{
    margin-bottom: 1rem;
}


.Checkout_PromoCodeContainer__3tHiC,
.Checkout_WalletContainer__dfuyH,
.Checkout_PaymentMethodContainer__bljGt,
.Checkout_SummaryContainer__1jZVD{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 1rem 2rem;
}
.Checkout_PromoCodeContainer__3tHiC h4,
.Checkout_WalletContainer__dfuyH h4,
.Checkout_PaymentMethodContainer__bljGt h4,
.Checkout_SummaryContainer__1jZVD h4{
    color:#FA5456
}

.Checkout_PromoCodeContainer__3tHiC img,
.Checkout_WalletContainer__dfuyH img{
    width: 20px;
    vertical-align: baseline;
}
.Checkout_PromoCodeContainer__3tHiC .Checkout_Hint__1A-R4,
.Checkout_WalletContainer__dfuyH .Checkout_Hint__1A-R4{
    font-size: 14px;
    font-weight: 500;
    color: #939393;
}
.Checkout_applyPromoCodeContainer__bBKPz{
    position: relative;
}
.Checkout_applyPromoCodeInput__zOfAs{
    padding: 1rem;
    border-radius: 15px;
}
.Checkout_applyPromoCodeBtnApplied__MzkyJ{
    position: absolute;
    right: 5px;
    top: 10%;
    border: none;
    padding-block: 0.7rem ;
    border-radius: 15px;
    color: #fff;
    background: #FA5456;
    font-weight: bold;
    width: 120px;
}
.Checkout_applyPromoCodeBtnNOTApplied__bun0G{
    background: #bebebe;
    position: absolute;
    right: 5px;
    top: 10%;
    border: none;
    padding-block: 0.7rem ;
    border-radius: 15px;
    color: #fff;
    font-weight: bold;
    width: 120px;
}


.Checkout_toggleWalletContainer__2vTzv{
    position: relative;
}

.Checkout_toggleWalletContainer__2vTzv input[type=checkbox] {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
.Checkout_toggleWalletContainer__2vTzv input[type=checkbox]:hover + label:after {
box-shadow: 0 2px 15px 0 #0002, 0 3px 8px 0 #0001;
}
.Checkout_toggleWalletContainer__2vTzv input[type=checkbox]:checked + label:before {
background: #0ae1ef;
}
.Checkout_toggleWalletContainer__2vTzv input[type=checkbox]:checked + label:after {
background: #08cad7;
left: 16px;
}
.Checkout_toggleWalletContainer__2vTzv label {
position: relative;
display: flex;
align-items: center;
}
.Checkout_toggleWalletContainer__2vTzv label:before {
content: "";
background: #bbb;
height: 12px;
width: 35px;
position: relative;
display: inline-block;
border-radius: 46px;
transition: 0.2s ease-in;
}
.Checkout_toggleWalletContainer__2vTzv label:after {
content: "";
position: absolute;
background: #fff;
top: -4px;
width: 19px;
height: 19px;
left: 0;
border-radius: 50%;
z-index: 2;
box-shadow: 0 0 5px #0002;
transition: 0.2s ease-in;
}

.Checkout_WalletContainer__dfuyH p span.Checkout_WalletBalance__poqz6{
font-weight: bold;
color:#939393;
margin-right: 2rem;
}


.Checkout_CheckBoxesContainer__239sP [type="radio"]:checked,
.Checkout_CheckBoxesContainer__239sP [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.Checkout_CheckBoxesContainer__239sP [type="radio"]:checked + label,
.Checkout_CheckBoxesContainer__239sP [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.Checkout_CheckBoxesContainer__239sP [type="radio"]:checked + label:before,
.Checkout_CheckBoxesContainer__239sP [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 14px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.Checkout_CheckBoxesContainer__239sP [type="radio"]:checked + label:after,
.Checkout_CheckBoxesContainer__239sP [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #FA5456;
    position: absolute;
    top: 17px;
    left: 3px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.Checkout_CheckBoxesContainer__239sP [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}
.Checkout_CheckBoxesContainer__239sP [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}
.Checkout_CheckBox__rTNQF{
    display: inline-block;
    margin-right: 15px;
}


.Checkout_PaymentMethodContainer__bljGt .Checkout_Visa__mPOob img{
    width: 50px;
    margin: 5px;
    height: 30px;
}

.Checkout_PaymentMethodContainer__bljGt .Checkout_Paypal__3KSEG img{
    width: 100px;
    margin: 5px;
}

@media (max-width: 699px) {

    .Checkout_ChackoutContainer__1BE3t{
        padding: 1rem;
    }
    .Checkout_CheckoutDetails__3IDYQ{
        padding: 0;
    }
    .Checkout_PromoCodeContainer__3tHiC,
    .Checkout_WalletContainer__dfuyH,
    .Checkout_PaymentMethodContainer__bljGt,
    .Checkout_SummaryContainer__1jZVD{
        padding: 1rem;
    }
    .Checkout_PaymentMethodContainer__bljGt .Checkout_Visa__mPOob img{
        width: 20px;
        margin-right: 1px;
        height: 15px;
    }
    .Checkout_PaymentMethodContainer__bljGt .Checkout_Paypal__3KSEG img{
        width: 80px;
        margin: 1px;
    }
    .Checkout_Visa__mPOob label,
    .Checkout_Paypal__3KSEG label{
        padding-top: 10px;
    }
}
.containerPayment {
  width: 100%;
  display: flex;
  justify-content: center;
}
.sectionPayment {
  width: 90%;
  align-items: center;
  text-align: center;
}
.imgPayment {
  width: 200px;
  height: auto;
}
.imgFailedPayment {
  width: 350px;
  height:auto;
}

.cartDataPaymet {
  margin-top: 40px;
}

.titleStatus {
  margin-top: 20px;
  font-weight: bold;
}

.freeContainer {
  border-radius: 15px;
  width: 80%;
  border-width: 2px;
  /* border-color: red; */
  justify-content: 'center';
  /* border: 3px solid  */
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: left;
  padding: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.titles {
  color: var(--maincolor);
}

.subTitles {
  color: var(--font);
  margin-left: 10px;
  font-size: 25px;
}

.Survey_SurveyTest__3Q2AX{
  padding-top: 5rem;
}

.Survey_Questions__7H9yM [type="radio"]:checked,
.Survey_Questions__7H9yM [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.Survey_Questions__7H9yM [type="radio"]:checked + label,
.Survey_Questions__7H9yM [type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.Survey_Questions__7H9yM [type="radio"]:checked + label:before,
.Survey_Questions__7H9yM [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.Survey_Questions__7H9yM [type="radio"]:checked + label:after,
.Survey_Questions__7H9yM [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #FA5456;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.Survey_Questions__7H9yM [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.Survey_Questions__7H9yM [type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.Survey_Question__1C331{
  background-color: #fff;
  display: flex;
  align-items: center;
  border: 2.5px solid #eee;
  padding: 1rem 2rem;
  border-radius: 15px;
  margin-bottom: 20px;
}

.Survey_QuestionTextarea__3wI0u{
  padding: 10px 20px;
  border-radius: 15px;
  border: solid 1px #efefef;
  background-color: #fafafa;
}
.AddAvailability_FormContainer__JlR_P {
  max-width: 100%;
}
.AddAvailability_FormPadding__3WeeS {
  padding: 2rem 10rem;
}
@media (max-width: 768px) {
  .AddAvailability_FormPadding__3WeeS {
    padding: 2rem;
  }
}
.AddAvailability_FormInput__Xktnr {
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background: #f9f9f9;
  border: none;
  color: #848484;
}

.CustomSelectRadio_RadioOption__2dzB7 {
  padding: 0.6rem 1rem;
  border-radius: 15px;
  border: 1px solid #FA5456;
}
.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:checked,
.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:checked + label,
.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 10px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  width: 100%;
}
.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:checked + label:before,
.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:checked + label:after,
.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #FA5456;
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.CustomSelectRadio_RadioOption__2dzB7 [type='radio']:checked + label:before {
  border: 1px solid #FA5456;
}

.CustomSelectRadio_DisabledInput__D4qzz {
  background: #e7e7e7;
  border: none !important;
}

.CustomSelectRadio_DisabledInput__D4qzz [type='radio']:checked + label:after,
.CustomSelectRadio_DisabledInput__D4qzz [type='radio']:not(:checked) + label:after,
.CustomSelectRadio_DisabledInput__D4qzz [type='radio']:checked + label:before,
.CustomSelectRadio_DisabledInput__D4qzz [type='radio']:not(:checked) + label:before {
  content: unset;
}

.MultiRangeCalendar_MultiRangeCalendar__3U_oB {
  position: relative;
  border: 1px solid #b9b9b9;
  border-radius: 15px;
}
.MultiRangeCalendar_selectDaysParagrah__1__Bi {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 25px;
}
.MultiRangeCalendar_selectDaysParagrah__1__Bi img {
  margin-right: 10px;
}
.MultiRangeCalendar_Clear__TfLnR {
  color: #00d7e5;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7%;
}

.BookedSession_Item__34ZSe{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
    position: relative;
    margin: 1rem;
    background-color: #fff;
    height: 100%;
}
.BookedSession_Expired__3zrqC{
    position: absolute;
    right: 0;
    top: 0;
    display: inline;
    width: auto;
    color: #fff;
    padding: 7px 40px;
    border-radius: 0px 10px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #07b2bd;
}
.BookedSession_SessionImage__3oKLF{
    padding: 0 !important;
}
.BookedSession_SessionImage__3oKLF img{
    border-radius: 10px;
    max-width: 240px;
}
.BookedSession_Details__25CCt{
    padding: 30px 30px !important;
}

.BookedSession_Details__25CCt h4{
    font-weight: bold;
    font-size:18px;
    color: #7b7b7b;
}

.BookedSession_Details__25CCt > p{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}
.BookedSession_Details__25CCt .BookedSession_SessionDate__OadIZ{
    color: #08cad7;
    font-weight: bold;
}
.AddAvailabilitySettings_Container__3oWQM {
  padding: 1rem 2rem;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
}
.AddAvailabilitySettings_CheckBoxContainer__32rJi {
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  padding: 1rem 2rem;
  margin-bottom: 15px;
}
.AddAvailabilitySettings_PriceBoxContainer__2QcF5,
.AddAvailabilitySettings_TimeBoxContainer__3ZSAb {
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  padding: 1rem 2rem;
  margin-bottom: 15px;
}
.AddAvailabilitySettings_PriceBoxContainer__2QcF5 input,
.AddAvailabilitySettings_PriceBoxContainer__2QcF5 input:focus,
.AddAvailabilitySettings_PriceBoxContainer__2QcF5 input:focus-visible,
.AddAvailabilitySettings_TimeBoxContainer__3ZSAb input,
.AddAvailabilitySettings_TimeBoxContainer__3ZSAb input:focus,
.AddAvailabilitySettings_TimeBoxContainer__3ZSAb input:focus-visible {
  border: none;
  outline: none;
}

.AddAvailabilitySettings_enableAvailability__3p4eD {
  position: relative;
}

.AddAvailabilitySettings_enableAvailability__3p4eD input[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.AddAvailabilitySettings_enableAvailability__3p4eD input[type='checkbox']:hover + label:after {
  box-shadow: 0 2px 15px 0 #0002, 0 3px 8px 0 #0001;
}
.AddAvailabilitySettings_enableAvailability__3p4eD input[type='checkbox']:checked + label:before {
  background: #FA5456;
}
.AddAvailabilitySettings_enableAvailability__3p4eD input[type='checkbox']:checked + label:after {
  background: #fff;
  left: 16px;
}
.AddAvailabilitySettings_enableAvailability__3p4eD label {
  position: relative;
  display: flex;
  align-items: center;
}
.AddAvailabilitySettings_enableAvailability__3p4eD label:before {
  content: '';
  background: #bbb;
  height: 12px;
  width: 35px;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  transition: 0.2s ease-in;
}
.AddAvailabilitySettings_enableAvailability__3p4eD label:after {
  content: '';
  position: absolute;
  background: #fff;
  top: -4px;
  width: 19px;
  height: 19px;
  left: 0;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 0 5px #0002;
  transition: 0.2s ease-in;
}

.AddAvailabilitySettings_HeaderTitle__J6GGA {
  margin: 1rem 0;
  text-align: center;
  border-bottom: 1px solid #e3e3e3;
  line-height: 0.1em;
}

.AddAvailabilitySettings_HeaderTitle__J6GGA span {
  background: #fff;
  padding: 0 10px;
}

.CustomCheckbox_CustomCheckbox__2Ht1R {
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.CustomCheckbox_CustomCheckbox__2Ht1R input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
}

.CustomCheckbox_CustomCheckbox__2Ht1R .CustomCheckbox_Checkmark__K2zGO {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 3px;
    outline: 1px solid #acacac;
    transition: all 0.2s ease;
}

.CustomCheckbox_CustomCheckbox__2Ht1R:hover .CustomCheckbox_Checkmark__K2zGO {
    background: #f4f4f5;
    transition: all 0.2s ease;
}

.CustomCheckbox_CustomCheckbox__2Ht1R input:checked ~.CustomCheckbox_Checkmark__K2zGO {
    background: #FA5456;
    outline: 1px solid #FA5456;
}

.CustomCheckbox_CustomCheckbox__2Ht1R input[type="radio"] ~ .CustomCheckbox_Checkmark__K2zGO {
    border-radius: 50%;
}

.CustomCheckbox_CustomCheckbox__2Ht1R .CustomCheckbox_Checkmark__K2zGO::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 40%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
}

.CustomCheckbox_CustomCheckbox__2Ht1R input:checked~.CustomCheckbox_Checkmark__K2zGO::after {
    opacity: 1;
    transition: all 0.2s ease;
}
.Quiz_personalityTest__1Z6Jv{
  padding-top: 2rem;
  margin-top: 20px;
  margin-bottom: 20px;
}
.Quiz_Questions__37JUk input + label{
  border-radius: 8px;
  border: 1px solid #9B9B9B;
  color: #9B9B9B;
  font-size: 15px;
  text-align: center;
  font-weight: 700;
  padding: 1rem;
  width: 100%;
  
}
.Quiz_Question__3bQtW input{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}
.Quiz_Questions__37JUk input:checked + label{
  background: #00B2EB;
  color: #fff;
  border: 1px solid #00B2EB;
}
/*
.Questions [type="radio"]:checked,
.Questions [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.Questions [type="radio"]:checked + label,
.Questions [type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.Questions [type="radio"]:checked + label:before,
.Questions [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.Questions [type="radio"]:checked + label:after,
.Questions [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #FF5659;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.Questions [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.Questions [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
  
.Question{
  background-color: #fff;
  display: flex;
  align-items: center;
  border: 2.5px solid #eee;
  padding: 1rem 2rem;
  border-radius: 15px;
  margin-bottom: 20px;
}
*/
.Quiz_QuestionFooter__2wSBu{
  border: solid 1px #ddd;
  background-color: #f8f8f8;
  padding: 40px;
}

.Quiz_NextButton__2VKxS{
  text-align: center;
  width: 100%;
  background-color: #FF5659;
  outline: none;
  border: 2px solid #FF5659;
  border-radius: 8px;
  padding: 10px 35px;
  font-weight: 600;
  font-size: 1.2rem;
  
}
.Quiz_NextButton__2VKxS:hover,
.Quiz_NextButton__2VKxS:active{
  background-color: #FF5659 !important; 
  border: 2px solid #FF5659;
}

.Quiz_NextButton__2VKxS:disabled{
  background-color: #FF5659 !important;
  border: 2px solid #FF5659;
  color: #fff;
  font-size: 1.2rem;
  opacity: 0.5;
}

.Quiz_PersonalityTestDesc__2GUFh{
  max-width: 500px;
  margin: auto;
}
.Quiz_QuestionNumber__1Ez9I{
  font-weight: bold;
}
.List_ListSearch__1mEnb{
    background-color: #f7f7f7;
    border: 0;
    padding: 15px 25px 15px 60px;
    border-radius: 15px;
}

.List_TopHead__1t7Lq{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;
    position: relative;
}

.List_FilterContainer__3GlfG{
    position: absolute;
    right: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 0;
    cursor: pointer;
}
.List_Filter__7cp-a{
    width: 50px;
    background: #e9fcf8;
    border-radius: 10px;
    padding: 10px 12px 10px 10px;
    position: relative;
    cursor: pointer;
}

@media (min-width:700px) {
    .List_FilterContainer__3GlfG{
        display: none;
    } 
}

.List_ConsultantsListSearch__2mxyV{
    background-color: #F9F9F9;
    border: 0;
    padding: 15px 20px 20px 25px;
    border-radius: 5px;
}

@media (max-width: 699px) {
    .List_ConsultantsListSearch__2mxyV{
        padding: 13px 20px 13px 50px;
    }
  }
.UserTrackingSystem_TrackingDetails__3fZMM{
    padding: 0 2rem 0 0;
}
.UserTrackingSystem_ActionButtons__sZFzf{
    display: flex;   
}
.UserTrackingSystem_SecondBottom__2gyWc{
    margin-left: 1rem;
}
.UserTrackingSystem_mainParagraph__3yALF{
    color: #6e6e6e;
    font-size: 1.1rem;
}
.UserTrackingSystem_TrackVideo__fNq8t{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
}
.UserTrackingSystem_TrackImgContainer__36494,
.UserTrackingSystem_CardImgContainer__4UuPs{
    overflow: hidden;
    height: auto;
    position: relative;
    border-radius: 10px;
}

@media only screen and (max-width: 699px) {
    .UserTrackingSystem_TrackingDetails__3fZMM{
        order: 2;
        padding: 0 1.5rem;
    }
    .UserTrackingSystem_TrackingMedia__ybmSG{
        order:1
    }
}

/*checkbox*/

  .UserTrackingSystem_AuctionType__2ky3h ul {
    list-style-type: none;
  }
  
  .UserTrackingSystem_AuctionType__2ky3h li {
    display: inline-block;
  }
  
  .UserTrackingSystem_AuctionType__2ky3h input[type="radio"][id^="name"] {
    display: none;
  }
  
  .UserTrackingSystem_AuctionType__2ky3h label {
    display: block;
    position: relative;
    margin: 10px 20px;
    cursor: pointer;
    
  
  }
  
  .UserTrackingSystem_AuctionType__2ky3h label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #FA5456;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .UserTrackingSystem_AuctionType__2ky3h label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  .UserTrackingSystem_AuctionType__2ky3h :checked + label img {
    border: 2px solid #FA5456;
    padding: 3px
  }

  .UserTrackingSystem_AuctionType__2ky3h :checked + label h6 {
    color: #FA5456;
  }
  
  .UserTrackingSystem_AuctionType__2ky3h :checked + label:before {
    
  }
  
  .UserTrackingSystem_AuctionType__2ky3h :checked + label img {
    z-index: -1;
  }

  .UserTrackingSystem_ArrowDate__39Uk8{
    margin: 0 35px;
}

.UserTrackingSystem_HeaderTitle__2hwSp{
  color: #08cad7;
  font-weight: 600;
}
.UserTrackingSystem_HeaderPargraph__1_z29{
  color: #b3b3b3;
  font-weight: 600;
}
.UserTrackingSystem_FormContainer__2BH_P{
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  background: #f9f9f9;
}

.UserTrackingSystem_FormPadding__16nux{
  padding: 2rem 10rem;
}

.UserTrackingSystem_TopImg__1aWK_{width: 300px;}

.UserTrackingSystem_FormInput__yY-i7{ 
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: #fff;
  border: none;
  color: #848484;
}

.UserTrackingSystem_togglePassword__1RVby{
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 10px;
}

.UserTrackingSystem_SendCode__fzwf_{
  color: #08cad7;
  text-decoration: underline !important;
}

/* The container */
.UserTrackingSystem_CheckBoxContainer__EQXKe {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.UserTrackingSystem_CheckBoxContainer__EQXKe input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.UserTrackingSystem_Checkmark__2Yqns {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #FA5456;
  border-radius: 4px;
}


/* When the checkbox is checked, add a blue background */
.UserTrackingSystem_CheckBoxContainer__EQXKe input:checked ~ .UserTrackingSystem_Checkmark__2Yqns {
  background-color: #FA5456;
  
}

/* Create the Checkmark/indicator (hidden when not checked) */
.UserTrackingSystem_Checkmark__2Yqns:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the Checkmark when checked */
.UserTrackingSystem_CheckBoxContainer__EQXKe input:checked ~ .UserTrackingSystem_Checkmark__2Yqns:after {
  display: block;
  
}

/* Style the Checkmark/indicator */
.UserTrackingSystem_CheckBoxContainer__EQXKe .UserTrackingSystem_Checkmark__2Yqns:after {
  left: 6px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  
}

.UserTrackingSystem_TermsAndConditions__10upX{
  text-decoration: underline;
  color: #FA5456;
  font-weight: 600;
}

.UserTrackingSystem_InviteCode__2Vhe2{
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 2rem;
}

.UserTrackingSystem_InviteCode__2Vhe2 h5{
  margin-bottom: 15px;
}


.UserTrackingSystem_InviteHint__2kK10{
  color: #FA5456;
  opacity: .9;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .UserTrackingSystem_TopImg__1aWK_{width: 200px;}
  .UserTrackingSystem_FormPadding__16nux{padding: 2rem;}
}



.UserTrackingSystem_Drop__17q3U{ 
height: 48px;
padding: 14px 25px;
margin: 0px 5px;
border-radius: 15px;
background-color:white;
border: none;
color: #848484;
}
.UserTrackingSystem_Drop__17q3U:hover,
.UserTrackingSystem_Drop__17q3U:active,
.UserTrackingSystem_btn__J8swb:first-child:active,
.UserTrackingSystem_Drop__17q3U.UserTrackingSystem_btn__J8swb.UserTrackingSystem_show__9MeLA{
background-color:white !important;
color: #848484 !important;
}


.UserTrackingSystem_Day__3K5h6{
  text-align: center;
  width: 70px;
  height: 70px;
  border: 1px solid #f1f1f1;
  flex-direction: column;
  justify-content: center;
  border-radius: 17px;
  margin: 0px 10px;
  cursor: pointer;
  display: inline-block;
  padding: 5px 20px;
  text-wrap: nowrap;
}
.UserTrackingSystem_ActiveDay__38soi{
  background-color: #FA5456;
  color: #fff;
  border-radius: 17px;
  border: none;
  width: 80px;
  transition: all .1s ease-in;
  margin: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TrackingDetailsAfterEnroll_Textarea__1rvg1{
    padding: 10px 20px;
    border-radius: 15px;
    border: solid 1px #efefef;
    background-color: #fafafa;
}

/*checkbox*/

.TrackingDetailsAfterEnroll_AuctionType__1tEGZ ul {
    list-style-type: none;
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__1tEGZ li {
    display: inline-block;
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__1tEGZ input[type="radio"][id^="name"] {
    display: none;
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__1tEGZ label {
    display: block;
    position: relative;
    margin: 10px 20px;
    cursor: pointer;
    
  
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__1tEGZ label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #FA5456;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__1tEGZ label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__1tEGZ :checked + label img {
    border: 2px solid #FA5456;
    padding: 3px
  }

  .TrackingDetailsAfterEnroll_AuctionType__1tEGZ :checked + label h6 {
    color: #FA5456;
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__1tEGZ :checked + label:before {
    
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__1tEGZ :checked + label img {
    z-index: -1;
  }

  
.TrackingDetailsAfterEnroll_ProgressValue__26vM5{
  position: absolute;
  top: -3.5rem;
  border-radius: 16px;
  padding: 3px 15px;
  z-index: 1;
  margin: 0;
}
.TrackingDetailsAfterEnroll_ProgressValue__26vM5:after {
  
  content: '';
  content: '';
  position: absolute;
  bottom: -17px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 15px #FA5456;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}

.TrackingDetailsAfterEnroll_SubmitBtn__3l4sJ{ 
  padding: .375rem .75rem;
    outline: none;
    border: 1px solid #eee;
    margin: 0 5px;
    border-radius: .375rem;
    color: #fff;
    background: #FA5456;
}

.TrackingDetailsAfterEnroll_CheckBoxesContainer__2wSNQ [type='radio']:checked,
.TrackingDetailsAfterEnroll_CheckBoxesContainer__2wSNQ [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.TrackingDetailsAfterEnroll_CheckBoxesContainer__2wSNQ [type='radio']:checked + label,
.TrackingDetailsAfterEnroll_CheckBoxesContainer__2wSNQ [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.TrackingDetailsAfterEnroll_CheckBoxesContainer__2wSNQ [type='radio']:checked + label:before,
.TrackingDetailsAfterEnroll_CheckBoxesContainer__2wSNQ [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.TrackingDetailsAfterEnroll_CheckBoxesContainer__2wSNQ [type='radio']:checked + label:after,
.TrackingDetailsAfterEnroll_CheckBoxesContainer__2wSNQ [type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #FA5456;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.TrackingDetailsAfterEnroll_CheckBoxesContainer__2wSNQ [type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.TrackingDetailsAfterEnroll_CheckBoxesContainer__2wSNQ [type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.TrackingDetailsAfterEnroll_CheckBox__1YYNn {
  display: inline-block;
  margin-right: 15px;
}
/* VideoPlayer.css */

.video-player {
    position: relative;
    width: 100%;
    max-width: 800px; /* Adjust as needed */
  }
  
  .video-player video {
    width: 100%;
    display: block;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and opacity */
  }
  
  .overlay img {
    cursor: pointer;
  }
  
  .overlay img:hover {
    opacity: 0.8; /* Adjust the hover opacity of the play button */
  }
.ActivitiesTab_Day__Mx40K{
    text-align: center;
    width: 70px;
    height: 70px;
    border: 1px solid #f1f1f1;
    flex-direction: column;
    justify-content: center;
    border-radius: 17px;
    margin: 0px 10px;
    cursor: pointer;
    display: inline-block;
    padding: 5px 20px;
    text-wrap: nowrap;
}
.ActivitiesTab_ActiveDay__1HyX7{
    background-color: #FA5456;
    color: #fff;
    border-radius: 17px;
    border: none;
    width: 80px;
    transition: all .1s ease-in;
    margin: 0;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ActivitiesTab_ProgressValue__1x1NQ{
    position: absolute;
    top: -3.5rem;
    border-radius: 16px;
    padding: 3px 15px;
    z-index: 1;
    margin: 0;
}
.ActivitiesTab_ProgressValue__1x1NQ:after {
    
    content: '';
    content: '';
    position: absolute;
    bottom: -17px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 15px #FA5456;
    border-left: solid 15px transparent;
    border-right: solid 15px transparent;
}
.TrackingDetailsAfterEnroll_Textarea__1h9lO{
    padding: 10px 20px;
    border-radius: 15px;
    border: solid 1px #efefef;
    background-color: #fafafa;
}

/*checkbox*/

.TrackingDetailsAfterEnroll_AuctionType__191RX ul {
    list-style-type: none;
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__191RX li {
    display: inline-block;
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__191RX input[type="radio"][id^="name"] {
    display: none;
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__191RX label {
    display: block;
    position: relative;
    margin: 10px 20px;
    cursor: pointer;
    
  
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__191RX label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #FA5456;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__191RX label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__191RX :checked + label img {
    border: 2px solid #FA5456;
    padding: 3px
  }

  .TrackingDetailsAfterEnroll_AuctionType__191RX :checked + label h6 {
    color: #FA5456;
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__191RX :checked + label:before {
    
  }
  
  .TrackingDetailsAfterEnroll_AuctionType__191RX :checked + label img {
    z-index: -1;
  }

  
.TrackingDetailsAfterEnroll_ProgressValue__JKrJr{
  position: absolute;
  top: -3.5rem;
  border-radius: 16px;
  padding: 3px 15px;
  z-index: 1;
  margin: 0;
}
.TrackingDetailsAfterEnroll_ProgressValue__JKrJr:after {
  
  content: '';
  content: '';
  position: absolute;
  bottom: -17px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 15px #FA5456;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}
.SessionDetails_ActionBtns__3HwW2 button{
    background-color: #fff;
    flex-grow: 1;
    border-radius: 15px;
    padding: 15px;
    font-size: 22px;
    margin: 10px;
}


.SessionDetails_ActionBtns__3HwW2 button:first-child{
    color:#FA5456 ;
    border: 1px solid #FA5456;
}

.SessionDetails_ActionBtns__3HwW2 button:last-child{
    color:#07B2BD ;
    border: 1px solid #07B2BD;
}
.RequestConsultation_HeaderTitle__14awG {
  color: #08cad7;
  font-weight: 600;
}
.RequestConsultation_TopImg__3Q4bF {
  width: 300px;
}
.RequestConsultation_HeaderPargraph__24Ego {
  color: #b3b3b3;
  font-weight: 600;
}
.RequestConsultation_FormContainer__1GgB8 {
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  background: #f9f9f9;
}

.RequestConsultation_LoginBackground__2xzTS {
  width: 100%;
}

.RequestConsultation_FormPadding__2M0hF {
  padding: 0rem 1rem;
}

.RequestConsultation_FormInput__8c3_W,
.RequestConsultation_TextArea__fXXwQ {
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: #fff;
  border: none;
  color: #848484;
}
.RequestConsultation_FormInput__8c3_W {
  height: 48px;
  border-radius: 15px;
  background-color: #fff;
}

.RequestConsultation_forgetPassword__3IRkz {
  text-align: right;
}
.RequestConsultation_CreateOne__mBduk {
  margin-left: 5px;
  color: #FA5456 !important;
}

.RequestConsultation_CheckBoxesContainer__2D8_C [type='radio']:checked,
.RequestConsultation_CheckBoxesContainer__2D8_C [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.RequestConsultation_CheckBoxesContainer__2D8_C [type='radio']:checked + label,
.RequestConsultation_CheckBoxesContainer__2D8_C [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.RequestConsultation_CheckBoxesContainer__2D8_C [type='radio']:checked + label:before,
.RequestConsultation_CheckBoxesContainer__2D8_C [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.RequestConsultation_CheckBoxesContainer__2D8_C [type='radio']:checked + label:after,
.RequestConsultation_CheckBoxesContainer__2D8_C [type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #FA5456;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.RequestConsultation_CheckBoxesContainer__2D8_C [type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.RequestConsultation_CheckBoxesContainer__2D8_C [type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.RequestConsultation_CheckBox__2JbPG {
  display: inline-block;
  margin-right: 15px;
}

@media (max-width: 768px) {
  .RequestConsultation_FormPadding__2M0hF {
    padding: 2rem;
  }
  .RequestConsultation_TopImg__3Q4bF {
    width: 200px;
  }
}

.RequestConsultation_Drop__2a9sb {
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: white;
  border: none;
  color: #848484;
}
.RequestConsultation_Drop__2a9sb:hover,
.RequestConsultation_Drop__2a9sb:active,
.RequestConsultation_btn__1gn5S:first-child:active,
.RequestConsultation_Drop__2a9sb.RequestConsultation_btn__1gn5S.RequestConsultation_show__2URI- {
  background-color: white !important;
  color: #848484 !important;
}

.ConsultantPages_ConsultantPages__Bvww6{
    background: #f9f9f9;
}

@media (min-width: 699px) {
    .ConsultantPages_ConsultantPages__Bvww6{padding: 4rem 0}
}
.consultant-pages .nav-link{
    background-color: transparent;
    padding: 20px 30px 20px;
    font-weight: 600;
    color: #1C0A26;
    font-size: 13px;
}
.consultant-pages .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--blue) ;
    background-color: transparent;
    border-bottom: 1px solid var(--blue);
    border-radius: 0;
}

.top-head-sidebar{
    background: #fff;
    padding: 20px;
}
.your-balance{
    background: #eee;
    padding: 0;
    font-weight: 500;
    font-size: 19px;
    margin:0 0 15px 0;
    border-radius: 10px;
    overflow: hidden;
}
.your-balance .title{
    padding: 10px 30px
}
.your-balance .value{
    background: #7b7b7b;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}

.consultant-pages li button{
    background-color: transparent !important;
}
.course-item{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
    position: relative;
}
.course-type{
    position: absolute;
    right: 0;
    top: 0;
    display: inline;
    width: auto;
    color: #fff;
    padding: 7px 40px;
    border-radius: 0px 10px;
    font-size: 1.2rem;
    font-weight: bold;
}
.live-course-type{
    background-color: #FA5456;
}
.recorded-course-type{
    background-color: #FF5B5B;
}
.course-image{
    padding: 0 !important;
}
.course-image img{
    border-radius: 10px;
    max-width: 240px;
}
.course-details{
    padding: 30px 30px !important;
}

.course-details h4{
    font-weight: bold;
    font-size:18px;
    color: #7b7b7b;
}

.course-details > p{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}
.course-details .course-provider{
    color: #08cad7;
    font-weight: bold;
}



.rate-number{
    color: #7b7b7b;
    font-weight: 600;
    font-size: 1.15rem;
    margin-left: 10px;
}

.course-views{
    color: #b3b3b3;
    font-weight: 500;
    font-size: 1.2rem;
}
.course-views-icon{
    margin-right: 5px;
}
.course-price{
    font-size: 1.3rem;
    font-weight: bold;
    text-align: right;
    padding: 50px 2rem !important;
}

.course-price .discount-price{
    color:#FA5456
}

.course-price .real-price{
    text-decoration: line-through;
    color: #b3b3b3;
    text-decoration-thickness: 2px;
}
.PrivateTrackingRequest_TabTitleNumber__3zWPL{
    color: #fff;
    background: #FA5456;
    border-radius : 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: inline-block;
}
.List_ListSearch__3tmUN{
    background-color: #f7f7f7;
    border: 0;
    padding: 15px 25px 15px 60px;
    border-radius: 15px;
}

.List_TopHead__2gnPO{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;
    position: relative;
}

.List_FilterContainer__R0ZMp{
    position: absolute;
    right: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 0;
    cursor: pointer;
}
.List_Filter__2GNhW{
    width: 50px;
    background: #e9fcf8;
    border-radius: 10px;
    padding: 10px 12px 10px 10px;
    position: relative;
    cursor: pointer;
}

@media (min-width:700px) {
    .List_FilterContainer__R0ZMp{
        display: none;
    } 
}

.List_ConsultantsListSearch__1OT6M{
    background-color: #F9F9F9;
    border: 0;
    padding: 15px 20px 20px 25px;
    border-radius: 5px;
}

@media (max-width: 699px) {
    .List_ConsultantsListSearch__1OT6M{
        padding: 13px 20px 13px 50px;
    }
  }
.UserTrackingSystem_TrackingDetails__17wjO{
    padding: 0 2rem 0 0;
}
.UserTrackingSystem_ActionButtons__1j0Ys{
    display: flex;   
}
.UserTrackingSystem_SecondBottom__flRPK{
    margin-left: 1rem;
}
.UserTrackingSystem_mainParagraph__34Tqj{
    color: #6e6e6e;
    font-size: 1.1rem;
}
.UserTrackingSystem_TrackVideo__1wVpK{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
}
.UserTrackingSystem_TrackImgContainer__1qw1u,
.UserTrackingSystem_CardImgContainer__3dDps{
    overflow: hidden;
    height: auto;
    position: relative;
    border-radius: 10px;
}

@media only screen and (max-width: 699px) {
    .UserTrackingSystem_TrackingDetails__17wjO{
        order: 2;
        padding: 0 1.5rem;
    }
    .UserTrackingSystem_TrackingMedia__2CJkx{
        order:1
    }
}

/*checkbox*/

  .UserTrackingSystem_AuctionType__3f2SQ ul {
    list-style-type: none;
  }
  
  .UserTrackingSystem_AuctionType__3f2SQ li {
    display: inline-block;
  }
  
  .UserTrackingSystem_AuctionType__3f2SQ input[type="radio"][id^="name"] {
    display: none;
  }
  
  .UserTrackingSystem_AuctionType__3f2SQ label {
    display: block;
    position: relative;
    margin: 10px 20px;
    cursor: pointer;
    
  
  }
  
  .UserTrackingSystem_AuctionType__3f2SQ label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #FA5456;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .UserTrackingSystem_AuctionType__3f2SQ label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  .UserTrackingSystem_AuctionType__3f2SQ :checked + label img {
    border: 2px solid #FA5456;
    padding: 3px
  }

  .UserTrackingSystem_AuctionType__3f2SQ :checked + label h6 {
    color: #FA5456;
  }
  
  .UserTrackingSystem_AuctionType__3f2SQ :checked + label:before {
    
  }
  
  .UserTrackingSystem_AuctionType__3f2SQ :checked + label img {
    z-index: -1;
  }

  .UserTrackingSystem_ArrowDate__26WRh{
    margin: 0 35px;
}
