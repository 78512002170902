.HeaderTitle{
    font-size: 30px;
    color: #101826;
    font-weight: 700;
}
.HeaderPargraph{
    font-size: 16px;
    font-weight: 400;
    color: #868686;
}
.FormContainer{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
}

.LoginBackground{
    width:100%
}

.FormPadding{
    padding: 7rem 10rem;
}

.Input{
    height: 48px;
    padding: 14px 45px;
    margin: 0px 5px;
    border: 1px solid #192252 !important;
    border-radius: 8px !important;
    border: none;
    color: #848484;
    background: transparent;
}

.forgetPassword{
    text-align: right;
}
.CreateOne{
    margin-left: 5px;
    color: #FA5456 !important;
}

.error{
    color: red;
    margin-top: 10px;
}

@media (max-width: 699px) {

    .FormPadding{
        padding: 1rem;
    }
}